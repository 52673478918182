import AuthService from '@/services/auth-service';
import type { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { useErrorStore } from '@/stores/error/error';
import { useMessageStore } from '@/stores/message/message';
import { globalRouterReference } from '@/router/globalRouterReference';

function handleServerError(): void {
  const messageStore = useMessageStore();

  messageStore.showMessage({
    key: 'errors.error_occurred',
    color: 'error',
    icon: 'fa fa-circle-exclamation'
  });
}

function handleAuthorizationError(): void {
  const errorStore = useErrorStore();
  errorStore.errorType = 'document_not_found';
  errorStore.backUrl =
    globalRouterReference.router!.currentRoute.value.fullPath;
}

function handleNotFoundError(): void {
  const errorStore = useErrorStore();
  errorStore.errorType = 'document_not_found';
}

function redirectToErrorPage(): void {
  if (globalRouterReference.router!.currentRoute.value.name === 'error-page')
    return;
  try {
    globalRouterReference.router!.push({ name: 'error-page' });
  } catch (e) {
    console.error('redirectToErrorPage error ', e);
  }
}

export function onRejected(error: AxiosError): Promise<never> {
  if (error.response?.status) {
    switch (error.response.status) {
      case 500:
        handleServerError();
        break;
      case 404:
        if (
          globalRouterReference.router!.currentRoute.value.name ===
          'document-create'
        ) {
          globalRouterReference.router!.push({ name: 'document-access' });
        } else if (
          globalRouterReference.router!.currentRoute.value.name ===
            'document-sign' ||
          globalRouterReference.router!.currentRoute.value.name === 'sign-id'
        ) {
          handleNotFoundError();
        }
        redirectToErrorPage();
        break;
      case 403:
        handleAuthorizationError();
        if (
          globalRouterReference.router!.currentRoute.value.name === 'sign-id' &&
          !AuthService.isAuthenticated
        ) {
          redirectToErrorPage();
        }
        break;

      case 401:
        if (
          globalRouterReference.router!.currentRoute.value.name ===
          'document-create'
        ) {
          globalRouterReference.router!.push({ name: 'document-access' });
        } else if (
          globalRouterReference.router!.currentRoute.value.name === 'sign-id'
        ) {
          redirectToErrorPage();
        }
        break;
    }
  }

  Sentry.captureException(error);
  return Promise.reject(error);
}
