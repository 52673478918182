<template>
  <div v-if="currentDocument" class="flex flex-col">
    <div class="!p-0 text-black mb-4">
      {{ i18n.t('texts.forward_text_hint') }}
    </div>

    <v-form ref="forwardForm" @submit.prevent="sendInvitations">
      <v-text-field
        v-for="(_, index) in emails"
        :key="`email-input-${index}`"
        v-model.trim="emails[index]"
        :label="i18n.t('labels.email')"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        type="email"
        autofocus
        class="mt-2 bg-white email-input"
        density="compact"
        hide-details
        variant="outlined"
        :rules="emailRules"
        clearable
        clear-icon="fal fa-circle-xmark"
        :append-icon="emails.length > 1 ? 'fal fa-xmark' : undefined"
        data-test-id="forward-invitation-email-input"
        @click:append="removeEmailAtIndex(index)"
      />
    </v-form>

    <button class="w-fit text-sm text-primary mb-4" @click="addEmail">
      {{ i18n.t('buttons.add_signatory') }}
    </button>

    <v-textarea
      v-model="message"
      density="compact"
      hide-details
      no-resize
      variant="outlined"
      :label="i18n.t('labels.add_message')"
      :placeholder="i18n.t('placeholders.add_message')"
    />

    <portal to="document-dialog-actions">
      <VBtnSecondary variant="outlined" color="primary" @click="closeDialog">
        {{ i18n.t('buttons.cancel') }}
      </VBtnSecondary>
      <VBtnPrimary
        data-test-id="send-invitation"
        elevation="0"
        :loading="sendingInvitations"
        :disabled="!formIsValid"
        color="primary"
        @click="sendInvitations"
      >
        {{
          i18n.t(
            emails.length === 1
              ? 'buttons.send_invitation'
              : 'buttons.send_invitations'
          )
        }}
      </VBtnPrimary>
    </portal>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useValidationRules } from '@/composables/useValidationRules';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useSignStore } from '@/stores/sign/sign';
import { cloneDeep } from '@/composables/useUtils';
import { useMessageStore } from '@/stores/message/message';
import { useEmitter } from '@/composables/useEmitter';

const i18n = useI18n();
const emit = defineEmits(['closeDialog']);
const { emailRules } = useValidationRules();

const { currentDocument } = useDocumentContext();
const signStore = useSignStore();
const messageStore = useMessageStore();
const emitter = useEmitter();
// FORM
const forwardForm = ref();
const emails = ref([null]);
const message = ref(null);
const sendingInvitations = ref(false);

// validation
const formIsValid = computed(() => {
  if (emails.value.length < 1) return false;

  const emailRegex =
    /^[\w!#$%&'*+/=?^_`{|}~-]+(\.[\w!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i;

  let value = true;
  emails.value.forEach((email) => {
    if (email === null) {
      value = false;
    } else {
      if (!emailRegex.test(email)) {
        value = false;
      }
    }
  });

  return value;
});

function addEmail() {
  emails.value.push(null);
}

function removeEmailAtIndex(index: number) {
  if (emails.value.length > 1) {
    emails.value.splice(index, 1);
  }
}

function closeDialog() {
  emails.value = [null];
  message.value = null;
  emit('closeDialog');
}

function forwardSignee(email: string) {
  if (signStore.signInfo?.signKey) {
    return signStore.forward(signStore.signInfo.signKey, {
      email,
      comment: message.value === '' ? null : message.value
    });
  }
}

function addSignee(email: string) {
  if (signStore.signInfo?.signKey && currentDocument.value) {
    return signStore.addNewSignee(currentDocument.value.documentId, {
      email: email,
      comment: message.value === '' ? null : message.value
    });
  }
}

async function sendInvitations() {
  const isValid = forwardForm.value.validate();
  const emailsToHandle = cloneDeep(emails.value);
  if (isValid && emailsToHandle.length > 0) {
    sendingInvitations.value = true;

    try {
      const emailToForward = emailsToHandle.pop();

      // Add all other signees
      const addPromises = emailsToHandle.map((email) => addSignee(email));
      await Promise.all(addPromises);

      // Forward to the remaining email (if there's one)
      if (emailToForward) {
        await forwardSignee(emailToForward);
      }

      messageStore.showMessage({
        key: 'snackbars.signature_forwarded',
        color: 'success',
        icon: 'fa fa-circle-check'
      });
    } catch (error) {
      console.error(error);
    } finally {
      emitter.$emit('update-sign-status');
      closeDialog();
      sendingInvitations.value = false;
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.v-input.v-text-field {
  margin-bottom: 0.75rem;
}

.email-input :deep(.slide-y-transition-leave-active) {
  transition: none !important;
}

.email-input :deep(.slide-y-transition-leave-to) {
  transition: none !important;
}

.email-input :deep(.slide-y-transition-enter-active) {
  transition: none !important;
}
.email-input :deep(.slide-y-transition-enter-to) {
  transition: none !important;
}
</style>
