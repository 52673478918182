<template>
  <portal :to="getPortalTitle">
    <img src="/svg/authority-services/did-logo.svg" alt="" />
  </portal>

  <portal :to="getPortalSubtitle">
    <div class="font-semibold text-base mb-4">
      {{ i18n.t('titles.check_identity') }}
    </div>
    <div class="mb-6 text-md">
      {{
        $vuetify.display.smAndDown && !showQr
          ? i18n.t('texts.check_identity_did_hint_mobile')
          : i18n.t('texts.check_identity_did_hint')
      }}
    </div>
  </portal>

  <div class="grid" style="width: 100%">
    <GetDeepIDCard
      v-if="
        ($vuetify.display.mdAndUp && deepAdminInternalStore.userValidations) ||
        showQr
      "
      :user-validations="deepAdminInternalStore.userValidations"
    />
    <MobileDeepIDSigningCard v-else-if="!showQr" @show-qr="showQr = true" />
  </div>
  <portal :to="getPortalActions">
    <component
      :is="
        $vuetify.display.smAndDown && !showQr ? 'VBtnSecondary' : 'VBtnPrimary'
      "
      :text="i18n.t('buttons.cancel')"
      @click="closeDialog"
    />
    <VBtnPrimary
      v-if="$vuetify.display.smAndDown && !showQr"
      color="primary"
      :text="i18n.t('buttons.download')"
      @click="getApp"
    />
  </portal>
</template>

<script setup lang="ts">
import { useDeepAdminInternalStore } from '@/stores/deepadmin/deepadmin-internal';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
const emit = defineEmits(['close-dialog']);

const deepAdminInternalStore = useDeepAdminInternalStore();
const i18n = useI18n();
const showQr = ref(false);
const route = useRoute();

const getPortalTitle = computed(() => {
  if (route.name === 'document-batch-sign') {
    return 'batch-identify-dialog-title';
  } else {
    return 'identify-dialog-title';
  }
});

const getPortalSubtitle = computed(() => {
  if (route.name === 'document-batch-sign') {
    return 'batch-identify-dialog-subtitle';
  } else {
    return 'identify-dialog-subtitle';
  }
});

const getPortalActions = computed(() => {
  if (route.name === 'document-batch-sign') {
    return 'batch-identify-dialog-actions';
  } else {
    return 'identify-dialog-actions';
  }
});

function closeDialog() {
  emit('close-dialog');
}

function getApp() {
  if (
    deepAdminInternalStore.userValidations &&
    deepAdminInternalStore.userValidations.slice(-1)[0].URL
  ) {
    window.open(
      deepAdminInternalStore.userValidations.slice(-1)[0].URL,
      '_self'
    );
  } else {
    closeDialog();
  }
}
</script>

<style scoped lang="scss">
.deepid-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}

.deep-id-hint {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  max-width: 412px;
  margin-bottom: 24px;
}
</style>
