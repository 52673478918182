<template>
  <div class="flex flex-col">
    <div class="!p-0 text-black mb-4">
      <I18nT
        v-if="['idle', 'progress'].includes(sendInvitationStatus)"
        keypath="texts.resend_invitation"
      >
        <template #email>
          {{ globalStore.selectedSignee?.email }}
        </template>
      </I18nT>

      <I18nT
        v-if="sendInvitationStatus === 'success'"
        keypath="texts.invitation_successfully_sent"
      >
        <template #email>
          {{ globalStore.selectedSignee?.email }}
        </template>
      </I18nT>
    </div>

    <portal to="document-dialog-actions">
      <VBtnSecondary variant="outlined" @click="emit('closeDialog')">
        <i18n-t keypath="buttons.close" />
      </VBtnSecondary>

      <VBtnPrimary
        v-if="['idle', 'progress'].includes(sendInvitationStatus)"
        ref="sendInvitationButton"
        color="primary"
        :loading="sendInvitationStatus === 'progress'"
        @click="sendInvitation"
      >
        <i18n-t keypath="buttons.resend" />
      </VBtnPrimary>
    </portal>
  </div>
</template>

<script setup lang="ts">
import { storeSigneeInvitation } from '@/api/deepsign/signee-invitation';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useGlobalStore } from '@/stores/global/global';
import { useMessageStore } from '@/stores/message/message';
import type { ComponentState } from '@/types/ui';
import { ref, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const emit = defineEmits(['closeDialog']);
const globalStore = useGlobalStore();
const messageStore = useMessageStore();
const { currentDocument } = useDocumentContext();

const sendInvitationStatus = ref<ComponentState>('idle');
const sendInvitationButton = ref();

async function sendInvitation() {
  try {
    sendInvitationStatus.value = 'progress';
    if (
      currentDocument.value?.documentId &&
      globalStore.selectedSignee?.signeeId
    ) {
      await storeSigneeInvitation(
        currentDocument.value?.documentId,
        globalStore.selectedSignee?.signeeId
      );
    } else {
      sendInvitationStatus.value = 'error';
    }
    sendInvitationStatus.value = 'success';
    const email = globalStore.selectedSignee?.email.slice();
    messageStore.showMessage({
      text: i18n.t('texts.invitation_successfully_sent', {
        email: email
      }),
      color: 'success',
      icon: 'fa fa-circle-check'
    });
    emit('closeDialog');
  } catch (error) {
    sendInvitationStatus.value = 'error';
  }
}

onMounted(() => {
  nextTick(() => {
    sendInvitationButton.value.$el.focus();
  });
});
</script>
