<template>
  <portal to="identify-dialog-title">
    <img src="/svg/authority-services/did-logo.svg" alt="" class="h-5" />
  </portal>
  <div>
    <v-card color="#F5F5F5" class="mb-4 pa-4" flat rounded="lg">
      <v-alert
        color="warning"
        variant="outlined"
        class="warning-message mb-4"
        rounded
      >
        <div class="d-flex flex-row align-center">
          <v-icon color="warning" class="mr-2" size="14"
            >fas fa-triangle-exclamation</v-icon
          >
          <div class="d-flex flex-column align-center">
            {{ i18n.t('dialogs.identify_warning.alert_text') }}
          </div>
        </div>
      </v-alert>
      <div
        v-if="
          authorityService === AuthorityService.MOBILE_ID &&
          jurisdiction === Jurisdiction.ZERTES
        "
        class="warning-hint"
      >
        {{
          i18n.t('dialogs.identify_warning.hint', {
            auth_service: i18n.t('authorities.sms_code')
          })
        }}
      </div>
      <div v-else class="warning-hint">
        {{
          i18n.t('dialogs.identify_warning.hint', {
            auth_service: i18n.t('authorities.ras')
          })
        }}
        <span class="warning-hint">
          <a
            href="https://check-signature.scapp.swisscom.com/"
            target="_blank"
            class="text-primary"
            style="text-decoration: none"
            rel="noopener"
          >
            {{ i18n.t('dialogs.identify_warning.check_phone_number') }}
          </a>
        </span>
      </div>
    </v-card>

    <template v-if="canUseDeepID">
      <div class="mb-3 warning-subtitle">
        {{ i18n.t('dialogs.identify_warning.subtitle') }}
      </div>

      <v-card class="deep-id-card" variant="outlined" rounded="lg">
        <!-- DISABLE EsLint here because of https://github.com/vuetifyjs/eslint-plugin-vuetify/issues/83 -->
        <!-- eslint-disable -->
        <v-img
          src="/svg/authority-services/did-logo.svg"
          height="20"
          position="left"
          class="mb-4"
        />
        <!-- eslint-enable -->
        <div class="deep-id-overline">
          {{ i18n.t('dialogs.identify_warning.overline') }}
        </div>
        <div class="deep-id-text">
          {{ i18n.t('dialogs.identify_warning.deep_id_text') }}
        </div>
      </v-card>
    </template>

    <portal to="identify-dialog-actions">
      <VBtnSecondary
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
        @click="closeDialog"
      />
      <VBtnPrimary
        v-if="canUseDeepID"
        color="primary"
        :text="i18n.t('buttons.continue_with_deep_id')"
        @click="continueWithDeepID"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import AuthService from '@/services/auth-service';
import { BetaFeature } from '@/types/enums/BetaFeature';
import { computed, type PropType } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import { AuthorityService } from '@/types/enums/AuthorityService';
import { useSignContext } from '@/composables/useSignContext';
import { Jurisdiction } from '@/types/enums/Jurisdiction';

const emit = defineEmits(['close-dialog']);

defineProps({
  authorityService: {
    type: String as PropType<AuthorityService>,
    default: ''
  }
});

const emitter = useEmitter();

const i18n = useI18n();

const { jurisdiction } = useSignContext();

const canUseDeepID = computed<boolean>(() => {
  return (
    import.meta.env.VITE_AUTHORITY_SERVICE_ENABLED === 'true' ||
    AuthService.hasBetaFeature(BetaFeature.DEEP_ID_SIGN)
  );
});

function closeDialog() {
  emit('close-dialog');
}

function continueWithDeepID() {
  emitter.$emit('set-authority-service', 'did');
  closeDialog();
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.card-title {
  font-weight: 600;
  font-size: 22px !important;
  line-height: 28px !important;
  margin-bottom: 24px;
  white-space: normal;
}

.warning-message {
  background-color: white !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
}

.deep-id-card {
  padding: 16px;
  background-color: white;
  border-color: $primary-color !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
}

.warning-hint {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #090909;
}

.warning-subtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #090909;
}

.deep-id-overline {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #121212;
  margin-bottom: 8px;
}

.deep-id-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #121212;
}
</style>
