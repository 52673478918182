import { nextTick } from 'vue';

export function useCreateStoreHelpers() {
  function compareString(first: string, second: string, insensitive = true) {
    if (!first || !second) {
      return false;
    }
    return insensitive
      ? first.toLowerCase() === second.toLowerCase()
      : first === second;
  }

  function validateEmail(mail: string) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/.test(
      mail
    );
  }

  async function focusInputField(inviteeId: string) {
    await nextTick(() => {
      const inviteeElement = window.document.getElementById(
        `email-${inviteeId}`
      );
      if (inviteeElement) {
        inviteeElement.focus();
      }
    });
  }

  function changeEmailAddress(signeeId: string, email: string) {
    const emailField = document.getElementById(`signature-email-${signeeId}`);
    if (emailField) {
      emailField.innerHTML = email;
    }
  }

  return {
    compareString,
    changeEmailAddress,
    focusInputField,
    validateEmail
  };
}
