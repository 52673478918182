<template>
  <DeepDialog
    v-model="dialog"
    width="600"
    :title="i18n.t('titles.sign_error_dialog')"
    @keydown.esc="closeDialog"
  >
    <template #card-title-content-icon>
      <div v-if="doesKeyExists" class="flex justify-center align-center mr-4">
        <div v-if="isSmsCode">
          {{ i18n.t('authorities.sms_code') }}
        </div>
        <img
          v-else
          :src="`/svg/authority-services/${authorityService}-logo.svg`"
          class="h-5"
          alt=""
        />
      </div>
    </template>
    <template #content>
      <v-card :flat="true" color="#F5F5F5" rounded="lg" class="error-card p-6">
        <v-alert color="error" class="error-alert" rounded variant="outlined">
          <div class="d-flex flex-row align-center">
            <v-icon color="error" class="mr-2" size="14">
              fal fa-circle-exclamation
            </v-icon>

            <div class="alert-text">
              {{ i18n.t('texts.signing_not_possible') }}
              <a
                v-if="
                  authorityService === AuthorityService.MOBILE_ID &&
                  errorKey === 'error.sign.user.serialNumber.mismatch'
                "
                :href="i18n.t('links.support_page')"
              >
                {{ i18n.t('texts.mobile_id.serialNumber.mismatch') }}
              </a>
              <a
                v-else-if="
                  !isConcurrentModification &&
                  doesKeyExists &&
                  isIdentityRelated
                "
                :href="learnMoreLink"
                target="_blank"
                class="text-primary"
              >
                {{ i18n.t('texts.learn_more') }}</a
              >
            </div>
          </div>
        </v-alert>
        <i18n-t
          v-if="documentError"
          class="error-description"
          :keypath="`errors.api${authorityTranslationKey}.${documentError}`"
          tag="p"
        >
          <template #email>
            <a
              v-if="authorityService === 'did'"
              class="text-primary"
              href="mailto:support@deepid.swiss"
              >support@deepid.swiss</a
            >
            <a v-else class="text-primary" href="mailto:support@deepcloud.swiss"
              >support@deepcloud.swiss</a
            >
          </template>
        </i18n-t>
        <i18n-t
          v-else
          class="error-description"
          :keypath="getErrorPath()"
          tag="p"
        >
          <template #email>
            <a class="text-primary" href="mailto:support@deepcloud.swiss"
              >support@deepcloud.swiss</a
            >
          </template>
        </i18n-t>
        <div v-if="!doesKeyExists" class="error-description">
          {{ i18n.t('texts.sign_error_dialog_reference') }}:
          <p v-if="errorKey">
            {{ errorKey }}
          </p>
          <p v-if="documentError">
            {{ documentError }}
          </p>
        </div>
      </v-card>
    </template>
    <template #actions>
      <component
        :is="shouldBePrimary"
        :text="buttonText"
        @click="closeDialog"
      />
      <VBtnPrimary
        v-if="
          authorityService === AuthorityService.MOBILE_ID &&
          errorKey &&
          errorKey.includes('mismatch')
        "
        :text="i18n.t('texts.mobile_id.serialNumber.mismatch')"
        @click="handleMismatch"
      >
      </VBtnPrimary>
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { constants } from '@/constants/constants';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { computed, onUnmounted, ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { SignatureMode } from '@/types/enums/SignatureMode';

const { currentDocument } = useDocumentContext();
const emitter = useEmitter();
const i18n = useI18n();
const dialog = ref(false);
const errorKey = ref();
const documentError = ref(null);
const authorityService = ref(null);

const UNKNOWN_ERROR = 'unknown_error';

emitter.$on('show-sign-error-dialog', showDialog);

onUnmounted(() => {
  emitter.$off('show-sign-error-dialog', showDialog);
});

const isSmsCode = computed(() => {
  return (
    currentDocument.value?.signatureMode === SignatureMode.ADVANCED &&
    authorityService.value === AuthorityService.MOBILE_ID
  );
});

const authorityTranslationKey = computed(() => {
  switch (authorityService.value) {
    case AuthorityService.DEEP_ID:
      return '.deep_id';
    case AuthorityService.MOBILE_ID:
      return '.mobile_id';
    default:
      return '';
  }
});

const learnMoreLink = computed(() => {
  let linkKey = '#';
  if (authorityService.value === AuthorityService.DEEP_ID) {
    linkKey = 'links.qes_deepid_failed';
  }
  if (authorityService.value === AuthorityService.MOBILE_ID) {
    linkKey = 'links.qes_mobileid_failed';
  }
  return i18n.t(linkKey);
});

const shouldBePrimary = computed(() => {
  return authorityService.value === AuthorityService.MOBILE_ID &&
    errorKey.value &&
    errorKey.value.includes('mismatch')
    ? 'VBtnSecondary'
    : 'VBtnPrimary';
});

const isConcurrentModification = computed(() => {
  return errorKey.value === constants.SIGN_ERROR_CONCURRENT_MODIFICATION;
});

const doesKeyExists = computed(() => {
  return (
    i18n.te(`errors.api${authorityTranslationKey.value}.${errorKey.value}`) ||
    (i18n.te(
      `errors.api${authorityTranslationKey.value}.${documentError.value}`
    ) &&
      documentError.value !== UNKNOWN_ERROR) ||
    i18n.te(`errors.api.${errorKey.value}`)
  );
});

const isIdentityRelated = computed(() => {
  return (
    i18n.te(`errors.api${authorityTranslationKey.value}.${errorKey.value}`) ||
    i18n.te(`errors.api${authorityTranslationKey.value}.${documentError.value}`)
  );
});

const buttonText = computed(() => {
  return i18n.t(
    isConcurrentModification.value
      ? 'buttons.close_and_refresh'
      : 'buttons.close'
  );
});

function handleMismatch() {
  const url =
    i18n.locale.value === 'de'
      ? 'https://support.deepbox.swiss/videos/deep-technologien/deepsign/signieren/qes-mit-mobile-id-nicht-moeglich/'
      : `https://support.deepbox.swiss/${i18n.locale.value}/videos/deep-technologies/deepsign/signieren/qes-mit-mobile-id-nicht-moeglich/`;
  window.open(url, '_blank');
}

function getErrorPath() {
  if (!doesKeyExists.value) {
    return 'errors.api.error.unknown_error';
  }
  if (i18n.te(`errors.api.${errorKey.value}`)) {
    return `errors.api.${errorKey.value}`;
  }

  if (i18n.te(`errors.api${authorityTranslationKey.value}.${errorKey.value}`)) {
    return `errors.api${authorityTranslationKey.value}.${errorKey.value}`;
  }

  if (
    i18n.te(`errors.api${authorityTranslationKey.value}.${documentError.value}`)
  ) {
    return `errors.api${authorityTranslationKey.value}.${documentError.value}`;
  }
  return 'errors.api.error.unknown_error';
}

function showDialog(event) {
  errorKey.value = event.errorKey;
  authorityService.value = event.authorityService;
  documentError.value = getDocumentError(event.documentErrors);
  if (!documentError.value && !errorKey.value) {
    documentError.value = UNKNOWN_ERROR;
  }
  dialog.value = true;
}

function getDocumentError(errors) {
  let orderedError = null;
  let orderNumber = null;
  if (errors?.documentIssues) {
    for (const issue of errors.documentIssues) {
      if (
        orderNumber > constants.SIGN_ERRORS_KEY_ORDER.indexOf(issue) ||
        orderNumber === null
      ) {
        orderNumber = constants.SIGN_ERRORS_KEY_ORDER.indexOf(issue);
        orderedError = issue;
      }
    }
    return orderedError;
  }
  return null;
}

function closeDialog() {
  if (isConcurrentModification.value) {
    emitter.$emit('update-sign-status');
  }
  dialog.value = false;
}
</script>

<style lang="scss" scoped>
.alert-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #d50000;

  :deep(a) {
    text-decoration: none !important;
  }
}

.error-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #090909;
  padding-top: 1rem;
}

.error-alert {
  background-color: white !important;
  padding: 12px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 30%;
}
</style>
