<template>
  <div class="flex flex-col">
    <div
      v-if="['idle', 'progress'].includes(addObserverStatus)"
      class="!p-0 text-black mb-4"
    >
      {{ i18n.t('texts.add_observer_text_hint') }}
    </div>
    <v-form
      v-if="['idle', 'progress'].includes(addObserverStatus)"
      ref="addObserverForm"
      @submit.prevent="sendInvitation"
    >
      <v-text-field
        v-model.trim="email"
        :label="i18n.t('labels.email')"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        type="email"
        autofocus
        class="mt-2 bg-white observer-email-input"
        density="compact"
        variant="outlined"
        :rules="emailRules"
        clearable
        clear-icon="fal fa-circle-xmark"
      />
    </v-form>

    <DeepSignAlert v-if="addObserverStatus === 'error'" class="mb-5" error>
      <div class="text-sm">
        {{
          i18n.t(isDuplicate ? 'errors.observer_duplicate' : 'errors.generic')
        }}
      </div>
    </DeepSignAlert>

    <portal to="document-dialog-actions">
      <VBtnSecondary variant="outlined" @click="closeDialog">{{
        i18n.t(
          addObserverStatus === 'error' ? 'buttons.close' : 'buttons.cancel'
        )
      }}</VBtnSecondary>
      <VBtnPrimary
        v-if="addObserverStatus !== 'error'"
        :loading="addObserverStatus === 'progress'"
        :disabled="!isFormValid"
        color="primary"
        @click="sendInvitation"
        >{{ i18n.t('buttons.send_invitation') }}</VBtnPrimary
      >

      <VBtnPrimary
        v-if="addObserverStatus === 'error' && isDuplicate"
        color="primary"
        @click="retry"
        >{{ i18n.t('buttons.retry') }}</VBtnPrimary
      >
    </portal>
  </div>
</template>

<script setup lang="ts">
import { addObserver } from '@/api/deepsign/sign-observer';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useValidationRules } from '@/composables/useValidationRules';
import { useMessageStore } from '@/stores/message/message';
import type { ComponentState } from '@/types/ui';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();
const messageStore = useMessageStore();
const addObserverForm = ref();
const { emailRules } = useValidationRules();
const email = ref<string | null>(null);
const emit = defineEmits(['closeDialog']);
const addObserverStatus = ref<ComponentState>('idle');
const { currentDocument } = useDocumentContext();
const isDuplicate = ref(false);

function closeDialog() {
  email.value = null;
  emit('closeDialog');
}

const isFormValid = computed(() => {
  if (!addObserverForm.value || !email.value) return false;
  return addObserverForm.value.isValid;
});

async function sendInvitation() {
  try {
    addObserverStatus.value = 'progress';
    const isValid = addObserverForm.value.validate();
    if (email.value && isValid && currentDocument.value) {
      const response = await addObserver(currentDocument.value.documentId, {
        email: email.value
      });
      currentDocument.value.observers.push(response.data);
      messageStore.showMessage({
        key: 'snackbars.observer_added',
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      closeDialog();
      addObserverStatus.value = 'success';
    } else {
      addObserverStatus.value = 'idle';
    }
  } catch (error) {
    if (error.response.status === 409) {
      isDuplicate.value = true;
    }
    addObserverStatus.value = 'error';
  }
}

async function retry() {
  email.value = null;
  addObserverStatus.value = 'idle';
  isDuplicate.value = false;
}
</script>

<style scoped lang="scss">
:deep(.v-text-field.v-text-field--enclosed) .v-text-field__details {
  padding-left: 0 !important;
}

.v-input :deep(.v-input__details) {
  padding-left: 0%;
  padding-top: 8px;
  min-height: 18px;
}

.v-input.v-text-field {
  margin-bottom: 0.75rem;
}

.observer-email-input :deep(.slide-y-transition-leave-active) {
  transition: none !important;
}

.observer-email-input :deep(.slide-y-transition-leave-to) {
  transition: none !important;
}

.observer-email-input :deep(.slide-y-transition-enter-active) {
  transition: none !important;
}
.observer-email-input :deep(.slide-y-transition-enter-to) {
  transition: none !important;
}
</style>
