<template>
  <DeepDialog
    v-model="dialog"
    width="600"
    :title="i18n.t('texts.reject_signature_process')"
    @keydown.esc="() => (dialog = false)"
  >
    <template #content>
      <v-card-text class="!p-0 text-black text-left mb-6">
        {{ i18n.t('text.reject_signature_process_subtitle') }}
      </v-card-text>
      <div class="text-sm text-zinc-600 mb-1">
        {{ i18n.t('titles.reject_signature_process_text') }}
      </div>
      <v-textarea
        ref="feedbackMessage"
        v-model="comment"
        :resizable="false"
        name="field-feedback-message"
        variant="outlined"
        :placeholder="i18n.t('placeholders.reject_signature_process')"
      />
    </template>
    <template #actions>
      <VBtnSecondary
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
        @click="cancelRejection"
      />
      <VBtnPrimary
        color="primary"
        :text="i18n.t('buttons.reject_now')"
        @click="handleRejection"
      />
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import { useEmitter } from '@/composables/useEmitter';
import { onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSignStore } from '@/stores/sign/sign';
import { useRouter } from 'vue-router';
import AuthService from '@/services/auth-service.ts';

const i18n = useI18n();
const emitter = useEmitter();
const dialog = ref(false);
const isApproverCase = ref(false);
const comment = ref();
const signStore = useSignStore();
const router = useRouter();

emitter.$on('open-reject-signature-process-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-reject-signature-process-dialog', openDialog);
});

function openDialog(approverValue: boolean = false) {
  dialog.value = true;
  isApproverCase.value = approverValue;
}

function handleRejection() {
  if (signStore.signInfo?.signKey) {
    if (AuthService.isAuthenticated) {
      signStore
        .rejectAuthSignature(
          {
            signKey: signStore.signInfo?.signKey,
            comment: comment.value
          },
          isApproverCase.value
        )
        .then(() => {
          router.push(
            `/document/${signStore.signInfo?.document.documentId}/details`
          );
          cancelRejection();
        });
    } else {
      signStore
        .rejectSignature(
          {
            signKey: signStore.signInfo?.signKey,
            comment: comment.value
          },
          isApproverCase.value
        )
        .then(() => {
          window.location.reload();
          cancelRejection();
        });
    }
  } else {
    console.error(
      'There was no SignKey provided for rejecting the signature process'
    );
  }
}

function cancelRejection() {
  dialog.value = false;
  comment.value = undefined;
}
</script>

<style lang="scss">
.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  margin: 0 0 30px;
  white-space: normal;
}
</style>
