/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles';

// Composables
import * as directives from 'vuetify/directives';
import { de, en, fr, it } from 'vuetify/locale';
import colors from 'vuetify/util/colors';
import { config, themes } from '@deepcloud/deep-ui-lib';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default config.createVuetifyConfig({
  directives,
  locale: {
    messages: {
      de,
      en,
      fr,
      it
    },
    locale: 'de'
  },
  theme: {
    variations: {
      colors: ['primary', 'secondary'],
      lighten: 2,
      darken: 0
    },
    themes: {
      light: {
        ...themes.deepSignLight,
        colors: {
          accent: colors.grey.darken3,
          greyDarken1: '#757575',
          black: '#212529',
          philippineSilver: '#B1B1B7',
          ...themes.deepSignLight.colors
        }
      },
      dark: {
        ...themes.deepSignDark
      }
    }
  },
  icons: {
    aliases: {
      ...{
        cancel: 'fat fa-xmark',
        expand: 'fal fa-chevron-down'
      }
    }
  },
  // display: {
  //   thresholds: {
  //     sm: 350
  //   }
  // },
  defaults: {
    // Vuetify Components
    VIcon: {
      size: 16
    },
    VAppBar: {
      flat: true,
      border: true
    },
    VMenu: {
      transition: 'fade-transition',
      VCard: {
        class: 'rounded-lg'
      },
      VList: {
        rounded: 'lg'
      }
    },
    VTextField: {
      variant: 'outlined',
      color: 'primary',
      density: 'compact'
    },
    VNavigationDrawer: {
      flat: true,
      border: true
    },
    VTextarea: {
      variant: 'outlined',
      color: 'primary',
      rounded: 'lg'
    },
    VSelect: {
      variant: 'outlined',
      color: 'primary',
      itemColor: 'primary',
      density: 'compact'
    },
    VSheet: {
      border: true,
      rounded: 'md'
    },
    VAutocomplete: {
      variant: 'outlined',
      color: 'primary',
      itemColor: 'primary',
      menuProps: {
        contentClass: 'rounded-lg'
      }
    },
    VCombobox: {
      color: 'primary',
      itemColor: 'primary',
      menuProps: {
        contentClass: 'rounded-lg'
      }
    },
    VSwitch: {
      color: 'primary',
      inset: true
    },
    VDivider: {
      color: 'accent'
    },
    VDataTableServer: {
      VSelectionControl: {
        color: 'primary'
      }
    },
    VCheckbox: {
      color: 'primary'
    },
    VCheckboxBtn: {
      color: 'primary'
    },
    VChip: {
      variant: 'tonal'
    }
  }
});
