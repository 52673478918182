<template>
  <DeepMenuUser
    v-model="menu"
    v-model:locale="i18n.locale.value"
    :items="menuItems"
    :user="user"
    color=""
    :is-mobile="device?.isMobile"
    :available-locales="i18n.availableLocales"
  >
    <template #prepend-custom>
      <div
        v-if="!AuthService.isAuthenticated && !isDocumentAuthAuthenticated"
        class="p-2"
      >
        <VBtn
          class="mb-4 w-full"
          variant="flat"
          color="primary"
          @click="createAccount"
        >
          {{
            emailHasAccount
              ? i18n.t('buttons.login')
              : i18n.t('buttons.create_account')
          }}
        </VBtn>
        <VDivider class="mx-2" />
      </div>
    </template>
  </DeepMenuUser>
</template>

<script lang="ts" setup>
import { computed, inject, onBeforeMount, ref, watch } from 'vue';
import { DeepMenuUser } from '@deepcloud/deep-ui-lib';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useSettingsDevStore } from '@/stores/settings/settings-dev';
import { DeviceKey } from '@/plugins/device-detector-js.ts';
import AuthService from '@/services/auth-service.ts';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useLanguage } from '@/composables/useLanguage.ts';
import { useDocumentAuth } from '@/composables/useDocumentAuth.ts';
import { PreferenceOptions } from '@/types/enums/PreferenceOptions.ts';
import { useStorage } from '@vueuse/core';
import { usePreferencesStore } from '@/stores/deepadmin/preferences.ts';
import { useOnboarding } from '@/composables/useOnboarding.ts';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin.ts';
import { useSignStore } from '@/stores/sign/sign.ts';

const { setLanguage, initializeLanguage } = useLanguage();
const settingsDevStore = useSettingsDevStore();
const emitter = useEmitter();
const route = useRoute();
const device = inject(DeviceKey);
const documentAuth = useDocumentAuth();
const i18n = useI18n();
const expertSettings = useStorage('expertSettings', false, sessionStorage);
const preferencesStore = usePreferencesStore();
const onboarding = useOnboarding();
const signStore = useSignStore();
const { emailHasAccount } = useDeepAdminStore();

watch(
  () => i18n.locale.value,
  async (newValue, oldValue) => {
    if (
      newValue !== oldValue &&
      typeof newValue === 'string' &&
      i18n.availableLocales.includes(newValue)
    ) {
      if (AuthService.isAuthenticated) {
        await setLanguage(newValue);
      }
    }
  }
);

const menu = ref(false);

const user = computed(() => {
  if (AuthService.isAuthenticated) {
    return AuthService?.user
      ? {
          ...AuthService.user,
          displayName:
            AuthService.user?.display_name || AuthService.user?.name || ''
        }
      : {};
  }
  return null;
});

const isDraftMode = computed(
  () => route.name === 'document-create' || route.name === 'p-document-create'
);

const isDocumentAccessRoute = computed(() => {
  return route.name === 'document-access';
});

const isDocumentAuthAuthenticated = computed(() => {
  return documentAuth.isAuthenticated.value;
});

const isUserAuth = computed(
  () =>
    AuthService.isAuthenticated &&
    !documentAuth.isAuthenticated.value &&
    !isDocumentAccessRoute.value
);

const knowledgeCenterUrl = computed(() => {
  let url = import.meta.env.VITE_KNOWLEDGE_CENTER_URL;
  switch (i18n.locale.value) {
    case 'en':
      url += '/en/';
      break;
    case 'fr':
      url += '/fr/';
      break;
    case 'it':
      url += '/it/';
      break;
    case 'de':
      break;
  }
  return url;
});

const menuItems = computed(() => {
  return [
    {
      key: 'deep_admin',
      title: i18n.t('buttons.manage_account'),
      dataTestId: 'item-deep-admin',
      show: () => AuthService.isAuthenticated && !isDraftMode.value,
      href: `${import.meta.env.VITE_DEEPADMIN_BASE_URL}account/info`,
      target: '_blank'
    },
    {
      key: 'deep_sign_expert_settings',
      title: i18n.t('buttons.show_expert_settings'),
      dataTestId: 'item-show-expert-settings',
      show: () => isDraftMode.value,
      action: () => toggleExpertSettings(),
      append: {
        icon: {
          icon: expertSettings.value
            ? 'far fa-toggle-large-on'
            : 'far fa-toggle-large-on fa-rotate-180',
          color: expertSettings.value
            ? 'primary opacity-100'
            : 'rgba(158, 158, 158, 40%)',
          size: '36'
        }
      }
    },
    {
      key: 'language',
      dataTestId: 'user-language',
      title: i18n.t('buttons.language')
    },
    {
      key: 'support',
      title: i18n.t('buttons.support'),
      dataTestId: 'item-support',
      items: [
        {
          key: 'send_feedback',
          title: i18n.t('buttons.send_feedback'),
          dataTestId: 'item-send-feedback',
          show: () => isUserAuth.value,
          action: () => {
            emitter.$emit('open-feedback-dialog');
            menu.value = false;
          }
        },
        {
          key: 'need_help',
          title: i18n.t('buttons.need_help'),
          dataTestId: 'item-contact-support',
          show: () => isUserAuth.value,
          action: () => {
            emitter.$emit('open-support-dialog');
            menu.value = false;
          }
        },
        {
          key: 'knowledge_center',
          title: i18n.t('buttons.knowledge_base'),
          dataTestId: 'item-knowledge-base',
          href: knowledgeCenterUrl.value,
          target: '_blank',
          action: () => {
            window.open(knowledgeCenterUrl.value, '_blank');
            menu.value = false;
          }
        }
      ]
    },
    {
      show: () =>
        AuthService.isAuthenticated && settingsDevStore.devModeEnabled,
      divider: true,
      space: 'ma-2'
    },
    {
      key: 'dev-mode',
      title: `DEV mode: ${settingsDevStore.devModeEnabled ? 'enabled' : 'disabled'}`,
      class: settingsDevStore.devModeEnabled ? 'success--text' : 'error--text',
      prepend: {
        icon: {
          icon: 'far fa-bug',
          color: settingsDevStore.devModeEnabled ? 'success' : 'error'
        }
      },
      dataTestId: 'item-dev-mode',
      show: () =>
        AuthService.isAuthenticated && settingsDevStore.devModeEnabled,
      action: () => {
        menu.value = false;
        settingsDevStore.devShowSettings = !settingsDevStore.devShowSettings;
      },
      append: {
        flag: 'DEV'
      }
    },
    {
      show: () => !isDocumentAccessRoute.value && AuthService.isAuthenticated,
      divider: true,
      space: 'ma-2'
    },
    {
      key: 'logout',
      title: i18n.t('buttons.logout'),
      dataTestId: 'item-logout',
      show: () => !isDocumentAccessRoute.value && AuthService.isAuthenticated,
      action: () => logout()
    }
    /* {
      key: 'login',
      title: i18n.t('buttons.login'),
      dataTestId: 'item-login',
      show: () => isDocumentAccessRoute.value && !AuthService.isAuthenticated,
      action: () => AuthService.login()
    }*/
  ];
});

const me = computed(() => {
  return AuthService.isAuthenticated
    ? AuthService.user?.email
    : signStore.signKeyInfo.email;
});

function toggleExpertSettings() {
  expertSettings.value = !expertSettings.value;
  if (AuthService.isAuthenticated) {
    preferencesStore.setPreference(
      PreferenceOptions.EXPERT_SETTINGS,
      expertSettings.value
    );
  }
}

async function logout() {
  try {
    await AuthService.logout({});
  } catch (error) {
    console.error('Error during logout', error);
  }
}

async function createAccount() {
  if (emailHasAccount) {
    const redirectUri = import.meta.env.VITE_BASE_URL + route.fullPath;
    await AuthService.login({
      redirectUri,
      loginHint: me.value
    });
  } else {
    onboarding.openRegisterPage(route.fullPath, me.value);
  }
}

onBeforeMount(async () => {
  await initializeLanguage();
  if (preferencesStore?.preferences?.expertSettings) {
    expertSettings.value = preferencesStore?.preferences?.expertSettings;
  }
});
</script>
