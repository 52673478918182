import { KeycloakKeyIds } from '@/constants/auth';
import type {
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
  KeycloakRegisterOptions
} from 'keycloak-js';
import Keycloak from 'keycloak-js';
import { useGlobalCookies } from '@/composables/useGlobalCookies';
import type { Cookie } from 'universal-cookie';
import { useEmitter } from '@/composables/useEmitter';
import { isLocalOrDevEnv } from '@/utils/env.ts';
import type { AuthBetaFeatures } from '@/types/AuthBetaFeatures.ts';
import i18n from '@/plugins/i18n';

const emitter = useEmitter();

export type KeycloakRegisterOptionsCustom = Omit<
  KeycloakRegisterOptions,
  'prompt'
> & {
  prompt?: 'none' | 'login' | 'consent' | 'force_login_hint';
};

export interface CallbackPayload {
  newValue?: string;
  oldValue?: string;
}

class AuthService {
  #kc: Keycloak | undefined;
  #cookies: Cookie | undefined;

  async initKeycloak(onAuthenticatedCallback: () => void) {
    this.#cookies = useGlobalCookies();
    this.#kc = new Keycloak({
      url: `${import.meta.env.VITE_IDP_URL}/${
        import.meta.env.VITE_IDP_URL_AUTH
      }`,
      realm: import.meta.env.VITE_IDP_REALM,
      clientId: import.meta.env.VITE_IDP_CLIENT_ID
    });

    this.#kc
      .init({
        enableLogging: isLocalOrDevEnv(),
        timeSkew: 0,
        onLoad: 'check-sso',
        checkLoginIframe: false,
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        scope: import.meta.env.VITE_IDP_SCOPES.replaceAll(',', ' '),
        token: sessionStorage.getItem(KeycloakKeyIds.KC_TOKEN),
        refreshToken: sessionStorage.getItem(KeycloakKeyIds.KC_REFRESH_TOKEN),
        pkceMethod: 'S256'
      })
      .then((authenticated: boolean) => {
        console.log('Keycloak initialized', authenticated);

        if (this.refreshToken) {
          this.updateAllStorageIds();
        } else {
          this.clearAllStorageIds();
        }
        onAuthenticatedCallback();
      })
      .catch((e) => {
        this.clearAllStorageIds();
        console.error('Keycloak init failed ', e);
      });
  }

  get isAuthenticated() {
    return this.#kc?.authenticated;
  }

  get user() {
    if (this.isAuthenticated) {
      return this.#kc?.tokenParsed;
    }
    return {};
  }

  get userLang() {
    if (this.isAuthenticated) {
      return this.user?.locale;
    }
    return null;
  }

  get betaFeatures() {
    if (this.isAuthenticated) {
      return this.user?.beta_features || [];
    }
    return [];
  }

  get keycloakCookieID() {
    return `${import.meta.env.VITE_ENVIRONMENT}.${
      KeycloakKeyIds.KC_SESSION_ID
    }`;
  }

  async login(options: KeycloakLoginOptions) {
    sessionStorage.removeItem('documentToken');
    await this.#kc?.login({
      redirectUri: options?.redirectUri ?? import.meta.env.VITE_ID_REDIRECT_URI,
      scope:
        options?.scope ?? import.meta.env.VITE_IDP_SCOPES.replaceAll(',', ' '),
      loginHint: options?.loginHint ?? undefined,
      ...options
    });
  }

  async fetchUser() {
    try {
      if (this.#kc) {
        const userInfos = await this.#kc.loadUserInfo();
        return Promise.resolve(userInfos);
      } else {
        throw new Error('No valid Keycloak instance');
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async logout(options: KeycloakLogoutOptions) {
    try {
      let redirectUri = import.meta.env.VITE_IDP_REDIRECT_URI;
      if (options?.redirectUri) {
        ({ redirectUri } = options);
      }
      await this.#kc?.logout({ redirectUri });
      return Promise.resolve();
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  async updateToken(minValidity = 0) {
    try {
      const didChange = await this.#kc?.updateToken(minValidity);
      if (didChange) {
        // Update store
        if (this.refreshToken) {
          this.updateAccessTokenInSessionStorage();
          this.updateRefreshTokenInSessionStorage();
          if (this.sessionId) {
            this.updateSessionId();
          }
        }
      } else {
        if (this.sessionId) {
          this.updateSessionId();
        }
        // Do nothing
      }
      return Promise.resolve(didChange);
    } catch (error) {
      emitter.$emit('open-session-invalid-dialog', {
        message: 'SESSION_EXPIRED'
      });
      return Promise.reject(new Error('Error updating token.'));
    }
  }

  // Handle Tokens
  // Access Token
  static saveAccessTokenInSessionStorage(accessToken: string) {
    sessionStorage.setItem(KeycloakKeyIds.KC_TOKEN, accessToken);
  }

  updateAccessTokenInSessionStorage() {
    if (this.accessToken) {
      AuthService.saveAccessTokenInSessionStorage(this.accessToken);
    }
  }

  static clearAccessTokenInSessionStorage() {
    sessionStorage.removeItem(KeycloakKeyIds.KC_TOKEN);
  }

  get accessToken() {
    return this.#kc?.token;
  }

  get accessTokenTyp() {
    return this.#kc?.tokenParsed?.typ;
  }

  get fullAccessToken() {
    return `${this.accessTokenTyp} ${this.accessToken}`;
  }

  // Refresh Token
  static saveRefreshTokenInSessionStorage(refreshToken: string) {
    sessionStorage.setItem(KeycloakKeyIds.KC_REFRESH_TOKEN, refreshToken);
  }

  updateRefreshTokenInSessionStorage() {
    if (this.refreshToken) {
      AuthService.saveRefreshTokenInSessionStorage(this.refreshToken);
    }
  }

  static clearRefreshTokenInSessionStorage() {
    sessionStorage.removeItem(KeycloakKeyIds.KC_REFRESH_TOKEN);
  }

  get refreshToken() {
    return this.#kc?.refreshToken;
  }

  // Session ID
  saveSessionId(sessionId: string) {
    this.#cookies?.setKey(this.keycloakCookieID, sessionId);
  }

  updateSessionId() {
    if (this.sessionId) {
      this.saveSessionId(this.sessionId);
    }
  }

  clearSessionId() {
    this.#cookies?.removeKey(this.keycloakCookieID);
  }

  get sessionId() {
    return this.#kc?.sessionId;
  }

  listenIfSessionIdCookieExists(
    callback: (callbackPayload: CallbackPayload) => void,
    interval = 1000
  ) {
    let sessionIdCookieLast = this.#cookies?.getKey(this.keycloakCookieID);
    setInterval(() => {
      const sessionIdCookie = this.#cookies?.getKey(this.keycloakCookieID);
      if (sessionIdCookie !== sessionIdCookieLast) {
        try {
          callback({
            oldValue: sessionIdCookieLast,
            newValue: sessionIdCookie
          });
        } finally {
          sessionIdCookieLast = sessionIdCookie;
        }
      }
    }, interval);
  }

  // Handle all ids at the same time
  clearAllStorageIds() {
    AuthService.clearAccessTokenInSessionStorage();
    AuthService.clearRefreshTokenInSessionStorage();
    this.clearSessionId();
  }

  updateAllStorageIds() {
    this.updateAccessTokenInSessionStorage();
    this.updateRefreshTokenInSessionStorage();
    this.updateSessionId();
  }

  hasRealmRole(role: string) {
    return this.#kc?.hasRealmRole(role) ?? false;
  }

  hasRealmRoles(roles: string[]) {
    roles.some((role) => this.#kc?.hasRealmRole(role) ?? false);
  }

  hasBetaFeature(betaFeature: AuthBetaFeatures) {
    return this.betaFeatures.includes(betaFeature);
  }

  async register(options?: KeycloakRegisterOptionsCustom) {
    await this.#kc.register({
      redirectUri:
        options?.redirectUri || import.meta.env.VITE_IDP_REDIRECT_URI,
      scope:
        options?.scope || import.meta.env.VITE_IDP_SCOPES.replaceAll(',', ' '),
      locale: i18n.global?.locale?.value || 'de',
      ...options
    });
  }
}

export default new AuthService();
