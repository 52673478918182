<template>
  <DeepDialog
    v-model="showDialog"
    :fullscreen="$vuetify.display.mdAndDown"
    :max-width="900"
    :title="i18n.t('texts.customize_signature')"
    @keydown.esc="() => (showDialog = false)"
  >
    <template #content>
      <div class="text-black text-base mb-4">
        {{ i18n.t('settings.subtitles.my_signature') }}
      </div>
      <div class="grid gap-8 md:grid-cols-8 w-full py-4">
        <v-form
          ref="userSignatureForm"
          class="grid w-full col-span-8 md:col-span-5"
        >
          <div class="grid gap-6">
            <SettingsSignaturePad
              :initial-signature-url="settings.autographUrl || ''"
              @update-signature-url="handleUpdateSignature"
            />

            <div>
              <label for="optionalText">{{
                i18n.t('settings.labels.optional_text')
              }}</label>
              <v-text-field
                id="optionalText"
                v-model.trim="settings.optionalText"
                :disabled="updatingState === 'loading'"
                :rules="[optionalTextValidationRule]"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :placeholder="i18n.t('settings.placeholders.optional_text')"
                type="text"
                @update:model-value="getPreviewDebounced()"
              />
            </div>
            <div>
              <label for="location">{{
                i18n.t('settings.labels.localtion')
              }}</label>
              <v-text-field
                id="location"
                v-model.trim="settings.location"
                :disabled="updatingState === 'loading'"
                :rules="[locationValidationRule]"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :placeholder="i18n.t('settings.placeholders.location')"
                type="text"
                class="form-input"
                @update:model-value="getPreviewDebounced()"
              />
            </div>
          </div>
        </v-form>

        <div
          class="gap-4 !order-first md:order-last md:col-span-3 col-span-8 w-full mt-4"
        >
          <div
            class="rounded-[0.65rem] border border-zinc-300 bg-zinc-100 p-4 shadow w-full"
          >
            <div class="relative">
              <div
                v-if="preview.isReady && !preview.state.value"
                class="!aspect-[851/448] !animate-pulse rounded-md border border-black/15 bg-white shadow-xl"
              ></div>
              <div>
                <img
                  v-if="preview.state.value"
                  :src="preview.state.value"
                  class="mx-auto w-full max-w-[488px] rounded-md border border-black/15 object-contain shadow-xl dark:border-white/15"
                />
                <OverlaySpinner
                  v-if="preview.state.value && preview.isLoading.value"
                />
              </div>
            </div>
          </div>
          <div
            v-if="preview.isReady && !preview.error.value"
            class="aspec max-w-xs text-center text-sm mx-auto py-4"
          >
            {{ i18n.t('settings.labels.signature_preview') }}
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <VBtnSecondary
        :disabled="updatingState === 'loading' || preview.isLoading.value"
        variant="outlined"
        @click="() => (showDialog = false)"
      >
        {{ i18n.t('buttons.cancel') }}
      </VBtnSecondary>
      <VBtnPrimary
        :loading="updatingState === 'loading'"
        :disabled="preview.isLoading.value"
        color="primary"
        @click="handleSubmit"
      >
        {{ i18n.t('buttons.save') }}
      </VBtnPrimary>
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { ref, reactive, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAsyncState } from '@vueuse/core';
import deepSignApi from '@/api/deepsign/deepsignApi';
import { base64ToFile, debounce } from '@/composables/useUtils';
import { useEmitter } from '@/composables/useEmitter';
import { useMessageStore } from '@/stores/message/message';
import { useSignStore } from '@/stores/sign/sign';
import { watch } from 'vue';
import type { AxiosResponse } from 'axios';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const i18n = useI18n();
const messageStore = useMessageStore();
const emitter = useEmitter();
const signStore = useSignStore();

const showDialog = ref(false);
const updatingState = ref<'idle' | 'loading' | 'error'>('idle');
const settings = ref<{
  autographUrl: null | string;
  optionalText: string;
  location: string;
}>({
  autographUrl: null,
  optionalText: '',
  location: ''
});
const previewChanged = ref(false);
const autographChanged = ref(false);

emitter.$on('open-signature-edition-dialog', () => (showDialog.value = true));

onUnmounted(() =>
  emitter.$off(
    'open-signature-edition-dialog',
    () => (showDialog.value = false)
  )
);

async function getPreviewPayload() {
  const formData = new FormData();
  if (settings.value.autographUrl) {
    formData.append(
      'autograph',
      await base64ToFile(settings.value.autographUrl, 'autograph')
    );
  }
  formData.append(
    'data',
    new Blob(
      [
        JSON.stringify({
          sizeFactor: 2,
          optionalText: settings.value.optionalText,
          location: settings.value.location
        })
      ],
      {
        type: 'application/json'
      }
    )
  );
  return formData;
}

// Initialize settings state using useAsyncState to fetch data and set up preview
const settingsInitialState = reactive(
  useAsyncState(
    async () => {
      const response = await deepSignApi.get('users/me');
      const data = response.data;
      settings.value.location = data.location;
      settings.value.optionalText = data.optionalText;
      settings.value.autographUrl = await fetchImageAsBase64(data.autographUrl);
      return data;
    },
    {},
    {
      resetOnExecute: false,
      shallow: false,
      immediate: false,
      onSuccess: async () => preview.execute(0, await getPreviewPayload())
    }
  )
);

const locationValidationRule = (value: string) =>
  value?.length <= 100 || i18n.t('settings.validation.location');

const optionalTextValidationRule = (value: string) =>
  value?.length <= 100 || i18n.t('settings.validation.optional_text');

const preview = useAsyncState(
  (visualSettings) =>
    deepSignApi
      .post(`users/me/signature/preview`, visualSettings, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob'
      })
      .then((t) => URL.createObjectURL(t.data)),
  '',
  { resetOnExecute: false, immediate: false }
);

const getPreviewDebounced = debounce(async () => {
  preview.execute(0, await getPreviewPayload());
  previewChanged.value = true;
}, 250);

async function fetchImageAsBase64(url: string) {
  if (!url) return null;
  const response = await fetch(url);
  const imageBlob = await response.blob();
  if (imageBlob) {
    return convertBlobToBase64(imageBlob);
  }
}

function convertBlobToBase64(blob: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

function handleUpdateSignature(newSignatureUrl: string) {
  settings.value.autographUrl = newSignatureUrl;
  autographChanged.value = true;
  getPreviewDebounced();
}

async function handleSubmit() {
  updatingState.value = 'loading';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const promises: Promise<AxiosResponse<any, any>>[] = [];

  if (autographChanged.value) {
    promises.push(
      settings.value.autographUrl
        ? saveAutograph(settings.value.autographUrl)
        : removeAutograph()
    );
  }

  if (previewChanged.value) {
    promises.push(saveSettings(settings.value));
  }

  Promise.all(promises)
    .then(() => {
      messageStore.showMessage({
        key: 'snackbars.signature_updated_success',
        color: 'success',
        icon: 'fa fa-circle-check',
        timeout: 2000
      });
      if (signStore.signInfo) {
        signStore.autographData = settings.value.autographUrl;
        signStore.signInfo.defaultAutographUrl = settings.value.autographUrl;
      }
      emitter.$emit('retrigger-preview');
      showDialog.value = false;
    })
    .catch(() => {
      messageStore.showMessage({
        key: 'snackbars.signature_updated_error',
        color: 'error',
        icon: 'fa fa-circle-exclamation',
        timeout: 2000
      });
    })
    .finally(() => (updatingState.value = 'idle'));
}

async function saveSettings(newSettings) {
  return deepSignApi.put('users/me', newSettings);
}

async function saveAutograph(autographUrl: string) {
  const formData = new FormData();
  formData.append('autograph', await base64ToFile(autographUrl, 'autograph'));
  return deepSignApi.post('users/me/autograph', formData, {
    headers: { 'content-type': 'multipart/form-data' }
  });
}

async function removeAutograph() {
  return deepSignApi.delete('users/me/autograph');
}

watch(
  () => showDialog.value,
  (dialogDisplayed) => {
    if (dialogDisplayed) {
      // Execute the initial fetch
      settingsInitialState.execute();
    }
  }
);
</script>
