<template>
  <DeepDialog
    v-model="dialog"
    max-width="600"
    min-width="300"
    min-height="400"
    :title="t('title.edit_image')"
    persistent
    @close="cancel"
  >
    <template #content>
      <div class="relative">
        <cropper
          ref="cropperRef"
          class="cropper"
          :class="{
            'is-remove-background': removeBackground,
            'is-disabled': !croppingFunctionality
          }"
          :src="imgSrc"
          :stencil-props="stencilProps"
          :move-image="croppingFunctionality"
          :resize-image="croppingFunctionality"
          :default-size="defaultSize"
          @ready="onReady"
        >
        </cropper>
        <div
          class="flex gap-2 justify-end w-full absolute bottom-0 text-primary p-4"
        >
          <button @click="zoom(2)">
            <v-icon>fa-regular fa-magnifying-glass-plus</v-icon>
          </button>
          <button @click="zoom(0.5)">
            <v-icon>fa-regular fa-magnifying-glass-minus</v-icon>
          </button>
          <button @click="rotate(-90)">
            <v-icon>fa-regular fa-arrow-rotate-left</v-icon>
          </button>
          <button @click="rotate(90)">
            <v-icon>fa-regular fa-arrow-rotate-right</v-icon>
          </button>
        </div>
      </div>
      <v-switch
        v-model="removeBackground"
        :label="t('components.cropping.remove_background')"
        hide-details
        density="compact"
        @update:model-value="onRemoveBackground"
      >
      </v-switch>
      <v-switch
        v-model="croppingFunctionality"
        :label="t('components.cropping.crop')"
        hide-details
        density="compact"
      >
      </v-switch>
    </template>

    <template #actions>
      <VBtnSecondary
        class="flex-shrink-1 min-w-[100px]"
        :class="{ 'w-full': $vuetify.display.mdAndDown }"
        color="primary"
        variant="outlined"
        @click="cancel"
        >{{ t('buttons.cancel') }}
      </VBtnSecondary>
      <VBtnPrimary
        class="flex-shrink-1 min-w-[100px]"
        :class="{ 'w-full': $vuetify.display.mdAndDown }"
        color="primary"
        variant="flat"
        :disabled="!isCropperReady"
        @click="onSubmit"
        >{{ t('buttons.save') }}
      </VBtnPrimary>
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  stencilOptions: {
    type: Object,
    default: () => {
      return {
        resizeImage: true
      };
    }
  },
  imgSrc: {
    type: String,
    default: undefined
  }
});

const { t } = useI18n();

const emit = defineEmits([
  'error',
  'cancel',
  'changed:cropped',
  'submit',
  'close'
]);

const cropperRef = ref<InstanceType<typeof Cropper> | null>(null);
const dialog = ref(false);
const removeBackground = ref(true);
const croppingFunctionality = ref(true);
const isCropperReady = ref(false);
const hasActionsBeenUsed = ref(false);
const originalImageSource = ref<string | null>(null);

const stencilProps = computed(() => {
  if (croppingFunctionality.value) {
    return {
      ...props.stencilOptions
    };
  }
  return {
    handlers: {},
    lines: {},
    moveImage: false,
    resizeImage: false
  };
});

function defaultSize({ imageSize }) {
  const ratio = 48 / 196;
  if (imageSize.height > imageSize.width * ratio) {
    return {
      width: imageSize.width * 0.8,
      height: imageSize.width * ratio
    };
  } else {
    return {
      width: imageSize.height * ratio,
      height: imageSize.height * 0.8
    };
  }
}

function reset() {
  if (cropperRef.value) cropperRef.value.reset();
  originalImageSource.value = null;
  isCropperReady.value = false;
  removeBackground.value = true;
  croppingFunctionality.value = true;
  hasActionsBeenUsed.value = false;
}

function destroy() {
  reset();
  dialog.value = false;
}

function cancel() {
  originalImageSource.value = null;
  emit('cancel');
  emit('close');
  destroy();
}

function resetToOriginalImage() {
  cropperRef.value!.imageAttributes.src = originalImageSource.value;
}

async function onSubmit() {
  const { image, canvas } = cropperRef.value!.getResult();
  // image cropped and or background transformed case
  if (
    (canvas && croppingFunctionality.value) ||
    (canvas && hasActionsBeenUsed.value)
  ) {
    emit('submit', canvas.toDataURL());
    // image un-cropped but background transformed case
  } else if (image && !croppingFunctionality.value && removeBackground.value) {
    emit('submit', image.src);
    // original image
  } else {
    emit('submit', props.imgSrc);
  }
  destroy();
}

function onReady() {
  isCropperReady.value = true;
  if (removeBackground.value) {
    changeImageAlphaChannel();
  }
}

function onRemoveBackground(newValue: boolean | null) {
  if (!isCropperReady.value) return;
  if (newValue) {
    changeImageAlphaChannel();
  } else {
    resetToOriginalImage();
  }
}

async function changeImageAlphaChannel() {
  if (!cropperRef.value) return;
  const { image, canvas } = cropperRef.value.getResult();
  if (!image || !canvas) return;

  try {
    const newImage = new Image();
    newImage.src = image.src as string;
    newImage.crossOrigin = 'anonymous';
    newImage.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext('2d', { willReadFrequently: true });
      if (!ctx) return;
      ctx.drawImage(newImage, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      const rgbaStep = 4;

      for (let i = 0; i < data.length; i += rgbaStep) {
        const redPartial = data[i];
        const greenPartial = data[i + 1];
        const bluePartial = data[i + 2];
        const partialIntensityThreshold = 130; //255 max -  0 min

        if (
          redPartial >= partialIntensityThreshold &&
          greenPartial >= partialIntensityThreshold &&
          bluePartial >= partialIntensityThreshold
        ) {
          data[i + 3] = 0; // alpha partial to 0
        }
      }

      ctx.putImageData(imageData, 0, 0);
      cropperRef.value!.imageAttributes.src = canvas.toDataURL();
    };
  } catch (e) {
    console.error('Error during changing of alpha canal');
  }
}

function zoom(factor: number) {
  if (!cropperRef.value) return;
  hasActionsBeenUsed.value = true;
  cropperRef.value.zoom(factor);
}

function rotate(degree: number) {
  if (!cropperRef.value) return;
  hasActionsBeenUsed.value = true;
  cropperRef.value.rotate(degree);
}

watch(
  () => props.imgSrc,
  (newValue) => {
    if (newValue) {
      originalImageSource.value = newValue;
    }
  }
);

defineExpose({ cropperRef, dialog });
</script>

<style scoped lang="scss">
@mixin stripes($lighterColor) {
  $lightColor: $lighterColor;
  $stripWidth: 10px;
  background-color: rgba(239, 239, 240, 0.9);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent $stripWidth,
    $lightColor $stripWidth,
    $lightColor ($stripWidth * 2)
  );
}

.cropper {
  height: 400px;
  min-height: 300px;
  width: 100%;
}

:deep(.vue-simple-handler) {
  background-color: rgba(0, 82, 255, 0.8);
}

:deep(.vue-simple-line) {
  background-color: rgba(0, 82, 255, 0.8);
}

:deep(.vue-advanced-cropper__foreground) {
  background-color: black !important;
}

.cropper.is-disabled {
  :deep(.vue-advanced-cropper__foreground) {
    opacity: 0 !important;
  }
  :deep(.vue-rectangle-stencil--movable) {
    pointer-events: none;
    cursor: default;
  }
  :deep(.vue-rectangle-stencil__preview) {
    display: none;
  }
}

:deep(.vue-advanced-cropper__background) {
  background: none !important;
}

:deep(.vue-rectangle-stencil__preview) {
  @include stripes(rgba(255, 255, 255, 1));
}
.cropper.is-disabled.is-remove-background {
  :deep(.vue-advanced-cropper__image) {
    @include stripes(rgba(255, 255, 255, 0.9));
  }
}
</style>
