<template>
  <v-bottom-sheet v-model="sheet" scrollable z-index="1000">
    <!-- BOTTOM SHEET ACTIVATION BUTTON -->
    <template #activator="{ props: scopedProps }">
      <div class="flex items-center mx-auto md:min-w-[404px] w-full md:w-auto">
        <DeepButton
          v-bind="scopedProps"
          :action="openSheet"
          :text="activatorText"
          data-test-id="get-started"
        />
      </div>
    </template>
    <!-- /BOTTOM SHEET ACTIVATION BUTTON -->

    <!-- CARD -->
    <v-card class="rounded-bottom-sheet" :height="cardHeight">
      <!-- BOTTOM SHEET HEADER -->
      <template #prepend>
        <v-btn icon size="small" @click="closeSheet">
          <v-icon size="small"> far fa-chevron-down</v-icon>
        </v-btn>
      </template>
      <template #title>
        <SwipeSurface @close-bottom-sheet="closeSheet" />
      </template>
      <template #append>
        <UserAccountMenu />
      </template>
      <!-- BOTTOM SHEET HEADER -->

      <!-- CARD TEXT -->
      <v-card-text class="!px-3 !pt-0">
        <div class="d-flex flex-column">
          <!-- DRAFT -->
          <create-card v-if="isDraft" />
          <PositionAndMessageCard
            v-if="isDraft"
            @enforce-authority-service="createStore.enforceAuthorityService"
          />
          <!-- /DRAFT -->

          <!-- SIGNING CARD -->
          <SealingCard
            v-if="route.name === 'document-seal'"
            :signatures="signees"
            :document="currentDocument"
            :can-sign="canSign"
          />
          <SigningCard
            v-else-if="shouldSign && !shouldApprove"
            :can-add-autograph="canAddAutograph"
            :can-sign="canSign"
            :document="currentDocument"
            :email="userEmail"
            :is-signed-by-user="isSignedByUser"
            :sign="sign"
            :sign-button-enabled="canSign"
            :signatures="signees"
            :authority-service-key="authorityService"
            @update-authority-service="setAuthorityService"
          />
          <!-- /SIGNING CARD -->

          <ApproverCard v-else-if="shouldApprove" />

          <ObserverCard v-else-if="!isDraft">
            <!-- ON HOLD ALERT -->
            <v-alert
              v-if="
                currentDocument &&
                currentDocument.documentStatus === DocumentStatus.IN_PROGRESS
              "
              class="deep-caption my-4"
              variant="outlined"
              type="info"
            >
              {{ i18n.t('texts.on_hold_generic_hint') }}
            </v-alert>
            <!-- /ON HOLD ALERT -->
          </ObserverCard>
          <!-- <AttachmentsUploadCard /> -->

          <!-- DOCUMENT INFO -->
          <DocumentInfo
            v-if="!isDraft"
            :document="currentDocument"
            :signees="signees"
            @update-signature-message="updateSignatureComment"
          />
          <!-- /DOCUMENT INFO -->
        </div>
      </v-card-text>
      <!-- /CARD TEXT -->

      <!-- CARD ACTIONS -->
      <div v-if="isDraft" class="bottom-sheet-footer">
        <div class="flex items-center justify-center w-full">
          <DeepButton
            :action="proceed"
            :disabled="isInvalid"
            :loading="startDocumentPending"
            data-test-id="continue"
            :text="
              invitationsCount > 0
                ? i18n.t('buttons.send_invitations')
                : i18n.t('buttons.continue')
            "
          />
        </div>
      </div>
      <!-- /CARD ACTIONS -->
    </v-card>
    <ConfirmationDialog ref="confirmationDialog" />
    <TimeOutDialog />
    <SignatureCancelledDialog />
    <SignErrorDialog />
    <SignatureEdition ref="signatureEdition" />
  </v-bottom-sheet>
</template>

<script setup lang="ts">
import SignatureService from '@/services/signatureService';
import AuthService from '@/services/auth-service';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { Jurisdiction } from '@/types/enums/Jurisdiction.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { storeStartedDocument } from '@/api/deepsign/start';
import { ref, computed, onUnmounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useRoute, useRouter } from 'vue-router';
import { useSubscriptionStore } from '@/stores/subscription/subscription';
import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { SignStatus } from '@/types/enums/SignStatus';
import { useSignStore } from '@/stores/sign/sign';
import { useMessageStore } from '@/stores/message/message';
import { usePreferencesStore } from '@/stores/deepadmin/preferences';
import { base64ToFile } from '@/composables/useUtils';
import { useSignContext } from '@/composables/useSignContext';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useTerms } from '@/composables/useTerms';
import { SigneeType } from '@/types/enums/SigneeType';
import { useCreateStore } from '@/stores/create.ts';
import { useEmailService } from '@/components/inputs/Emailsuggestion/useEmailService.ts';
import type { Document } from '@/types/Document.ts';

const { isDocumentAuthRoute, currentDocument } = useDocumentContext();
const { getTranslatedTerms } = useTerms();
const messageStore = useMessageStore();
const pollInterval = ref(null);
const preferencesStore = usePreferencesStore();
const signStore = useSignStore();
const subscriptionStore = useSubscriptionStore();
const { isGuest, userEmail, signKey, isSignatureTypeSeal } = useSignContext();

const emitter = useEmitter();
const i18n = useI18n();
const router = useRouter();
const route = useRoute();
const createStore = useCreateStore();

const agreement = ref(false);
const authorityService = ref(null);
const autographData = ref(null);
const clearingEmptyInvitations = ref(false);
const clearingEmptyObservers = ref(false);
const confirmationDialog = ref();
const firstOpening = ref(true);
const message = ref('');
const sheet = ref(false);
const signeeFields = ref([]);
const startDocumentPending = ref(false);
const useDefaultAutograph = ref(false);
const { saveRecentlyUsedEmails } = useEmailService(
  (currentDocument.value as Document).companyId
);

const isDraftMode = computed(() => route.name === 'document-create');
const documentStatus = computed(() => {
  return currentDocument.value && currentDocument.value.documentStatus;
});

const userFields = computed(
  () =>
    currentDocument.value?.signeesOrdered
      .flat()
      .filter((signee) =>
        isGuest.value
          ? signee.email === userEmail.value
          : SignatureService.compareString(
              signee.email,
              AuthService.user?.email
            )
      ) || []
);

const shouldSign = computed(() => {
  if (isDocumentAuthRoute.value) return false;

  const hasPendingActions = userFields.value.every((signee) => {
    const emailMatch = isGuest.value
      ? signee.email === userEmail.value
      : SignatureService.compareString(signee.email, AuthService.user?.email);

    return (
      currentDocument.value &&
      emailMatch &&
      signee.signeeType === SigneeType.SIGN &&
      signee.signStatus === SignStatus.SIGNED &&
      (currentDocument.value?.documentStatus === DocumentStatus.IN_PROGRESS ||
        currentDocument.value?.documentStatus === DocumentStatus.SIGNED ||
        currentDocument.value?.documentStatus === DocumentStatus.WITHDRAWN)
    );
  });
  return signStore.signInfo?.policy?.canSign || hasPendingActions;
});

const shouldApprove = computed(() => {
  const hasPendingActions = currentDocument.value?.signeesOrdered
    .flat()
    .every((signee) => {
      const emailMatch = isGuest.value
        ? signee.email === userEmail.value
        : SignatureService.compareString(signee.email, AuthService.user?.email);

      return (
        currentDocument.value &&
        emailMatch &&
        signee.signeeType === SigneeType.APPROVE &&
        signee.signStatus === SignStatus.APPROVED &&
        (currentDocument.value?.documentStatus === DocumentStatus.IN_PROGRESS ||
          currentDocument.value?.documentStatus === DocumentStatus.SIGNED ||
          currentDocument.value?.documentStatus === DocumentStatus.WITHDRAWN)
      );
    });
  return signStore.signInfo?.policy?.canApprove || hasPendingActions;
});

const didAlreadyApproved = computed(() => {
  const signees = currentDocument.value?.signeesOrdered || [[]]; // Default to an empty nested array if undefined

  return signees.flat().some((signee) => {
    const emailMatch = isGuest.value
      ? signee.email === userEmail.value
      : SignatureService.compareString(signee.email, AuthService.user?.email);

    return (
      emailMatch &&
      signee.signeeType === SigneeType.APPROVE &&
      signee.signStatus === SignStatus.APPROVED
    );
  });
});

const activatorText = computed(() => {
  switch (documentStatus.value) {
    case DocumentStatus.DRAFT:
      return firstOpening.value
        ? i18n.t('titles.get_started')
        : i18n.t('buttons.continue');
    case DocumentStatus.IN_PROGRESS:
      if (shouldApprove.value) {
        return i18n.t('buttons.continue_to_approve');
      }
      if (canSign.value) {
        if (didAlreadyApproved.value) return i18n.t('buttons.document_status');
        return isSignatureTypeSeal.value
          ? i18n.t('buttons.continue_to_seal')
          : i18n.t('buttons.continue_to_sign');
      } else {
        return i18n.t('buttons.document_status');
      }
    default:
      return i18n.t('buttons.document_status');
  }
});

const isDraft = computed(() => {
  return documentStatus.value === DocumentStatus.DRAFT;
});

const isSignedByUser = computed(() => {
  return SignatureService.isSignedByUser(userEmail.value, signees.value);
});

const isMissingSubscription = computed(() => {
  if (currentDocument.value && currentDocument.value.observers.length === 0) {
    return false;
  } else {
    return !subscriptionStore.hasValidSubscription;
  }
});

const canSign = computed(() => {
  if (isGuest.value) {
    return signStore.signInfo?.signStatus === SignStatus.PENDING;
  }
  if (AuthService.isAuthenticated) {
    return SignatureService.canSign(
      AuthService.user?.email,
      currentDocument.value
    );
  }
  return false;
});

const canAddAutograph = computed(() => {
  const mySignature = SignatureService.getSigneeByEmail(
    userEmail.value,
    currentDocument.value.signees
  );
  return (
    canSign.value &&
    (mySignature?.autographPosition !== null ||
      signStore.signInfo?.canModifyAutographPosition)
  );
});

const destination = computed(() => {
  if (SignatureService.onlyMe(AuthService.user?.email, currentDocument.value)) {
    return `/document/${currentDocument.value.documentId}/sign`;
  }
  return `/document/${currentDocument.value.documentId}/invitations-sent`;
});

const confirmationTitle = computed(() => {
  if (
    createStore.hasEmptyApprovers &&
    createStore.hasEmptyObservers &&
    createStore.hasEmptySignees
  ) {
    // Empty signees, approvers and observers
    return i18n.t('titles.undefined_signees_approvers_and_observers');
  } else if (createStore.hasEmptyObservers && createStore.hasEmptySignees) {
    // Empty signees and observers
    return i18n.t('titles.undefined_signees_and_observers');
  } else if (createStore.hasEmptyApprovers && createStore.hasEmptyObservers) {
    // Empty approvers and observers
    return i18n.t('titles.undefined_approvers_and_observers');
  } else if (createStore.hasEmptyApprovers && createStore.hasEmptySignees) {
    // Empty approvers and signees
    return i18n.t('titles.undefined_approvers_and_signees');
  } else if (createStore.hasEmptyApprovers) {
    // Only empty approver
    return i18n.t('titles.undefined_approvers');
  } else if (createStore.hasEmptySignees) {
    // Only empty signees
    return i18n.t('titles.undefined_signees');
  } else if (createStore.hasEmptyObservers) {
    // Only empty observers
    return i18n.t('titles.undefined_observers');
  } else {
    return i18n.t('titles.no_observers_subscription_title');
  }
});

const confirmationText = computed(() => {
  if (
    createStore.hasEmptyApprovers &&
    createStore.hasEmptyObservers &&
    createStore.hasEmptySignees
  ) {
    return i18n.t(
      'titles.undefined_signees_approvers_and_observers_confirmation_request'
    );
  } else if (createStore.hasEmptyObservers && createStore.hasEmptySignees) {
    // Empty signees and observers
    return i18n.t(
      'titles.undefined_signees_and_observers_confirmation_request'
    );
  } else if (createStore.hasEmptyApprovers && createStore.hasEmptyObservers) {
    // Empty approvers and observers
    return i18n.t(
      'titles.undefined_approvers_and_observers_confirmation_request'
    );
  } else if (createStore.hasEmptyApprovers && createStore.hasEmptySignees) {
    // Empty approvers and signees
    return i18n.t(
      'titles.undefined_approvers_and_signees_confirmation_request'
    );
  } else if (createStore.hasEmptySignees) {
    // Only empty signees
    return i18n.t('titles.undefined_signees_confirmation_request');
  } else if (createStore.hasEmptyApprovers) {
    // Only empty approver
    return i18n.t('titles.undefined_approver_confirmation_request');
  } else if (createStore.hasEmptyObservers) {
    // Only empty observers
    return i18n.t('titles.undefined_observers_confirmation_request');
  } else {
    return i18n.t('titles.no_observers_subscription_dialog_ask_owners');
  }
});

const cardHeight = computed(() => `${window.innerHeight * 0.9}px`);

const invitationsCount = computed(() => {
  const invites = currentDocument.value.signees.filter(
    (signee) =>
      !SignatureService.compareString(signee.email, AuthService.user?.email)
  );
  return invites.length;
});

const isInvalid = computed(() => {
  // Check we have at least one signee
  if (!createStore.hasSignees) {
    return true;
  }

  // If we only have empty fields
  if (createStore.filteredSignees.every((signee) => signee.email === null)) {
    return true;
  }

  // Check all strings are valid emails
  let hasInvalidStrings = false;
  if (signeeFields.value) {
    signeeFields.value
      .filter((s) => {
        return s.email !== null && s.email !== '';
      })
      .forEach((signee) => {
        if (validateEmail(signee.email)) {
          hasInvalidStrings = true;
        }
      });
  }

  return hasInvalidStrings;
});

const signees = computed(() => {
  if (isGuest.value) {
    return signStore.signInfo?.document.signees;
  } else {
    return currentDocument.value.signees;
  }
});

const signStatus = computed(() => {
  return signStore.signInfo?.signStatus;
});

watch(
  () => signStatus.value,
  (newValue, oldValue) => {
    // When signStatus is no more 'in-progress' clear poll and close loader
    if (signStatus.value !== SignStatus.IN_PROGRESS) {
      stopPolling();
      emitter.$emit('close-sign-pending-dialog');

      // If signStatus is 'pending', we have to check if there is an error and display it
      if (signStatus.value === SignStatus.PENDING) {
        if (!signStore.signInfo?.lastError) {
          return;
        } else if (
          signStore.signInfo?.lastError?.messageId === 'error.sign.user.timeout'
        ) {
          emitter.$emit('show-timeout-dialog');
        } else if (
          signStore.signInfo?.lastError?.messageId === 'error.sign.user.cancel'
        ) {
          emitter.$emit('show-signature-cancelled-dialog', {
            authorityService: authorityService.value
          });
        } else {
          emitter.$emit('show-sign-error-dialog', {
            errorKey: signStore.signInfo?.lastError?.messageId,
            authorityService: authorityService.value
          });
        }
      }

      // If signStatus is 'signed' check if we need to redirect to correct page
      if (signStatus.value === SignStatus.SIGNED) {
        stopPolling();
        const signature = document.getElementById(
          `signature-preview-${signStore.userSigneeId}`
        );
        if (signature) {
          signature.parentNode.removeChild(signature);
        }
        // Need to do this check to avoid showing the success message twice
        if (newValue !== oldValue) {
          if (!isGuest.value && isSignedByUser.value && currentDocument.value) {
            router.push({
              path: `/document/${currentDocument.value.documentId}/details`,
              query: { ...route.query }
            });
          }
          emitter.$emit('update-sign-status');
          messageStore.showMessage({
            key: 'snackbars.signed_text',
            color: 'success',
            icon: 'fa fa-circle-check'
          });
        }
      }
    }
  },
  {
    deep: true
  }
);

emitter.$on('change-autograph', updateChangeAutograph);
emitter.$on('close-bottom-sheet', closeSheet);
emitter.$on('open-bottom-sheet', openSheet);
emitter.$on('stop-sign-status-polling', stopPolling);
emitter.$on('update-agreement', updateAgreementStatus);
emitter.$on('update-attachments-option', createStore.updateAttachmentsAllow);
emitter.$on('update-authority-service', createStore.updateAuthorityService);
emitter.$on('update-autograph-data', updateSignatureData);
emitter.$on('update-signature-level', createStore.updateSignatureLevel);
emitter.$on('update-signature-message', createStore.updateSignatureMessage);
emitter.$on('update-signee-fields', updateSigneeFields);

onUnmounted(() => {
  emitter.$off('change-autograph', updateChangeAutograph);
  emitter.$off('close-bottom-sheet', closeSheet);
  emitter.$off('open-bottom-sheet', openSheet);
  emitter.$off('stop-sign-status-polling', stopPolling);
  emitter.$off('update-agreement', updateAgreementStatus);
  emitter.$off('update-attachments-option', createStore.updateAttachmentsAllow);
  emitter.$off('update-authority-service', createStore.updateAuthorityService);
  emitter.$off('update-autograph-data', updateSignatureData);
  emitter.$off('update-signature-level', createStore.updateSignatureLevel);
  emitter.$off('update-signature-message', createStore.updateSignatureMessage);
  emitter.$off('update-signee-fields', updateSigneeFields);
});

function validateEmail(email) {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
  return !reg.test(email);
}

function updateSigneeFields(value) {
  signeeFields.value = value;
}

function updateSignatureComment(newComment) {
  message.value = newComment;
}

function openSheet() {
  sheet.value = true;
}

function closeSheet() {
  firstOpening.value = false;
  sheet.value = false;
}

function saveRecentlyUsedEmailsToPreferences() {
  if (isDraftMode.value) {
    try {
      saveRecentlyUsedEmails(currentDocument.value!.signeesOrdered.flat());
    } catch (e) {
      console.error('Save recently used emails failed ', e);
    }
  }
}

async function startDocumentProcess() {
  try {
    startDocumentPending.value = true;
    await storeStartedDocument(currentDocument.value!.documentId);
    currentDocument.value.documentStatus = DocumentStatus.IN_PROGRESS;
    startDocumentPending.value = false;
    // emit open invitations dialog
    if (
      createStore.initiatorAsSignee &&
      createStore.filteredSignees.length === 1
    ) {
      await router.push({ path: destination.value, query: { ...route.query } });
    } else {
      emitter.$emit('open-invitations-dialog');
    }
  } catch (error) {
    startDocumentPending.value = false;
    console.error(error);
  }
}

function openCleanupConfirmationDialog() {
  confirmationDialog.value
    .open(confirmationTitle.value, confirmationText.value, null, {
      noCancel:
        isMissingSubscription.value &&
        !createStore.hasEmptySignees &&
        !createStore.hasEmptyObservers &&
        !createStore.hasEmptyApprovers,
      confirmActionText:
        isMissingSubscription.value &&
        !createStore.hasEmptySignees &&
        !createStore.hasEmptyObservers &&
        !createStore.hasEmptyApprovers
          ? i18n.t('buttons.close')
          : null
    })
    .then(async (result) => {
      if (result) {
        if (
          isMissingSubscription.value &&
          !createStore.hasEmptySignees &&
          !createStore.hasEmptyObservers &&
          !createStore.hasEmptyApprovers
        ) {
          return;
        }
        clearingEmptyInvitations.value = true;
        clearingEmptyObservers.value = true;
        await createStore.removeAllInviteeFieldsWithEmptyEmail();

        clearingEmptyInvitations.value = false;
        await createStore.removeAllObserversWithEmptyEmail();
        clearingEmptyObservers.value = false;

        if (!isMissingSubscription.value) {
          await storeStartedDocument(currentDocument.value!.documentId);
          currentDocument.value!.documentStatus = DocumentStatus.IN_PROGRESS;
          // emit open invitations dialog
          if (
            SignatureService.onlyMe(
              AuthService.user?.email,
              currentDocument.value
            )
          ) {
            await router.push({
              path: destination.value,
              query: { ...route.query }
            });
          } else {
            emitter.$emit('open-invitations-dialog');
          }
        } else {
          await proceed();
        }
      }
    });
}

async function proceed() {
  if (
    !createStore.hasEmptySignees &&
    !createStore.hasEmptyObservers &&
    !createStore.hasEmptyApprovers
  ) {
    saveRecentlyUsedEmailsToPreferences();
    await startDocumentProcess();
  } else {
    openCleanupConfirmationDialog();
  }
}

function setAuthorityService(newAuthorityService) {
  authorityService.value = newAuthorityService;
}

function updateChangeAutograph() {
  useDefaultAutograph.value = false;
}

function updateAgreementStatus(eventData) {
  agreement.value = eventData;
}

function updateSignatureData(eventData) {
  autographData.value = eventData;
}

function stopPolling() {
  clearInterval(pollInterval.value);
}

const isBasic = computed(() => {
  return currentDocument.value.signatureMode === SignatureMode.TIMESTAMP;
});

const isAdvancedEidas = computed(() => {
  return (
    currentDocument.value.signatureMode === SignatureMode.ADVANCED &&
    currentDocument.value.jurisdiction === Jurisdiction.EIDAS
  );
});

function getAutographData() {
  if (autographData.value) {
    return base64ToFile(autographData.value, 'autograph.png');
  }
  if (signStore.signInfo?.defaultAutographUrl) {
    return base64ToFile(
      signStore.signInfo?.defaultAutographUrl,
      'autograph.png'
    );
  }
  return null;
}

function checkAsynchronousSign() {
  try {
    if (signStore.asynchronousSign?.signStatus === SignStatus.IN_PROGRESS) {
      pollInterval.value = setInterval(() => {
        signStore.fetchAuthSignStatus(signKey.value);
      }, 2500);
      emitter.$emit('show-sign-pending-dialog', {
        document: currentDocument.value,
        authorityService: authorityService.value
      });
    }
  } catch (error) {
    signStore.signPending = false;
    console.error('Error polling sign status: ', error);
  }
}

async function sign() {
  try {
    signStore.signPending = true;
    let canProceed = false;
    if (
      signStore.signInfo?.policy.canModifySigneeAttachments &&
      signStore.signInfo?.signeeAttachments.length === 0
    ) {
      await confirmationDialog.value
        .open(
          i18n.t('titles.no_attachments'),
          i18n.t('texts.no_attachments_hint'),
          null,
          { confirmActionText: i18n.t('buttons.sign_anyway') }
        )
        .then((result) => {
          if (result) {
            canProceed = true;
          } else {
            signStore.signPending = false;
          }
        });
    } else {
      canProceed = true;
    }

    if (canProceed) {
      let payload = null;

      if (!AuthService.isAuthenticated) {
        await confirmationDialog.value
          .open(
            i18n.t('titles.signature_confirmation'),
            getTranslatedTerms('guest_ses'),
            // i18n.t('terms.guest_ses'),
            null,
            { confirmActionText: i18n.t('buttons.confirm'), width: 417 }
          )
          .then((result) => {
            canProceed = !!result;
            signStore.signPending = canProceed;
          });
      } else if (
        currentDocument.value.signatureMode !== SignatureMode.TIMESTAMP &&
        authorityService.value !== AuthorityService.DEEP_ID
      ) {
        const textKey = getTranslatedTerms(currentDocument.value.jurisdiction);
        await confirmationDialog.value
          .open(
            i18n.t('titles.signature_confirmation'),
            i18n.t(textKey),
            null,
            { confirmActionText: i18n.t('buttons.confirm'), width: 417 }
          )
          .then((result) => {
            canProceed = !!result;
            signStore.signPending = canProceed;
          });
      }

      if (canProceed) {
        if (isGuest.value) {
          payload = {
            signKey: signStore.signInfo?.signKey,
            sign: {
              autographPosition: signStore.signInfo?.canModifyAutographPosition
                ? signStore.getPlacedSignaturePosition
                : null,
              comment: message.value
            },
            autograph: await getAutographData()
          };
          try {
            await signStore.signDocument(payload);
            signStore.signPending = false;
          } catch (error) {
            signStore.signPending = false;
            emitter.$emit('show-sign-error-dialog', {
              errorKey: error.response.data?.messageId,
              authorityService: authorityService.value,
              documentErrors: error.response.data?.details
            });
          }
        } else {
          if (!isBasic.value && !isAdvancedEidas.value) {
            await preferencesStore.setPreference(
              'defaultAuthorityService',
              authorityService.value
            );
          }
          payload = {
            signKey: signKey.value,
            sign: {
              comment: message.value,
              authorityService: authorityService.value,
              autographPosition: signStore.signInfo?.canModifyAutographPosition
                ? signStore.getPlacedSignaturePosition
                : null
            },
            autograph: await getAutographData()
          };

          try {
            await signStore.signAuthDocument(payload);
            signStore.signPending = false;
          } catch (error) {
            emitter.$emit('show-sign-error-dialog', {
              errorKey: error.response.data?.messageId,
              authorityService: authorityService.value,
              documentErrors: error.response.data?.details
            });
          }
          checkAsynchronousSign();
        }
      }
    }
  } catch (error) {
    console.error('Error signing the document', error);
  }
}
</script>

<style scoped lang="scss">
.card-header {
  padding-bottom: 0 !important;
}
</style>
