import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { SignStatus } from '@/types/enums/SignStatus';
import { computed, type ComputedRef } from 'vue';
import { useEmitter } from './useEmitter';
import { downloadDocumentPdf } from '@/services/documentService.ts';
import { useRouter } from 'vue-router';
import { SignatureType } from '@/types/enums/SignatureType';
import { SigneeType } from '@/types/enums/SigneeType';
import i18n from '@/plugins/i18n';
import type { OverviewDocument } from '@/stores/overview/overview';

export function useDocumentActions(
  documentStatus: DocumentStatus,
  signStatus: SignStatus,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documents: ComputedRef<OverviewDocument[]>
) {
  const emitter = useEmitter();
  const router = useRouter();

  function getSignLabelAction(
    signatureType: SignatureType,
    signeeType: SigneeType
  ) {
    if (
      signatureType === SignatureType.SIGNATURE &&
      signeeType === SigneeType.SIGN
    ) {
      return i18n.global.t('buttons.sign');
    }
    if (signatureType === SignatureType.SEAL) {
      return i18n.global.t('buttons.seal');
    }
    if (
      signatureType === SignatureType.SIGNATURE &&
      signeeType === SigneeType.APPROVE
    ) {
      return i18n.global.t('buttons.approve');
    }
  }
  function getSignIconAction(
    signatureType: SignatureType,
    signeeType: SigneeType
  ) {
    if (
      signatureType === SignatureType.SIGNATURE &&
      signeeType === SigneeType.SIGN
    ) {
      return 'fa-regular fa-pen-nib';
    }
    if (signatureType === SignatureType.SEAL) {
      return 'fa-regular fa-stamp';
    }
    if (
      signatureType === SignatureType.SIGNATURE &&
      signeeType === SigneeType.APPROVE
    ) {
      return 'fa-regular fa-octagon-check';
    }
  }

  // Define commonly used actions as constants
  const downloadAction = {
    label: i18n.global.t('buttons.download'),
    icon: 'fa-regular fa-download',
    type: 'default',
    action: async () => {
      if (documents.value && documents.value.length > 0) {
        for (const document of documents.value) {
          await downloadDocumentPdf(
            document?.signKey,
            document?.documentName,
            document?.documentStatus,
            document?.documentId
          );
        }
      }
    }
  };

  const deleteAction = {
    label: i18n.global.t('buttons.delete'),
    icon: 'fa-regular fa-trash',
    type: 'warning',
    action: () => {
      emitter.$emit('open-delete-documents-dialog', {
        documents: documents.value
      });
    }
  };

  const withdrawAction = {
    label: i18n.global.t('buttons.withdraw'),
    icon: 'fa-regular fa-file-excel',
    type: 'default',
    action: () => {
      emitter.$emit('open-document-withdraw-dialog', documents.value);
    }
  };

  const signAction = {
    label: getSignLabelAction(
      documents.value[0].signatureType,
      documents.value[0].signeeType
    ),
    icon: getSignIconAction(
      documents.value[0].signatureType,
      documents.value[0].signeeType
    ),
    type: 'default',
    action: () =>
      router.push({
        name: 'document-sign',
        params: { document: documents.value[0].documentId }
      })
  };

  const batchSignAction = {
    label: i18n.global.t('buttons.sign'),
    icon: 'fa-regular fa-pen-nib',
    type: 'default',
    action: () => {
      router.push({ name: 'document-batch-sign' });
    }
  };

  const actions = computed(() => {
    const hasOneDocument =
      Array.isArray(documents.value) && documents.value.length === 1;
    const canDeleteDocuments = documents.value.every(
      (document) => document.policy.canDelete
    );
    const canWithdrawDocument = documents.value.every((document) => {
      return document.policy.canWithdraw;
    });

    const canSign = documents.value.every((document) => {
      return document.policy.canSign;
    });

    const canApprove = documents.value.every((document) => {
      return document.policy.canApprove;
    });

    const areSignature = documents.value.every((document) => {
      return document.signatureType === SignatureType.SIGNATURE;
    });

    const isMissingSignature = documents.value.every(
      (document) => document.signStatus === SignStatus.PENDING
    );

    // "To sign" tab
    if (
      documentStatus === DocumentStatus.IN_PROGRESS &&
      signStatus === SignStatus.PENDING
    ) {
      if (hasOneDocument) {
        return [
          ...(canSign || canApprove ? [signAction] : []),
          ...(canWithdrawDocument ? [withdrawAction] : []),
          downloadAction
        ];
      } else {
        return [
          ...(areSignature && canSign ? [batchSignAction] : []),
          downloadAction
        ];
      }
    }

    // "Draft" tab
    if (documentStatus === DocumentStatus.DRAFT) {
      return [downloadAction, deleteAction];
    }

    // "Pending" tab
    if (documentStatus === DocumentStatus.IN_PROGRESS) {
      if (hasOneDocument) {
        return [
          downloadAction,
          ...(canWithdrawDocument ? [withdrawAction] : []),
          ...(isMissingSignature && canSign ? [signAction] : [])
        ];
      } else {
        return [downloadAction];
      }
    }

    // "Completed" tab
    if (
      [
        DocumentStatus.REJECTED,
        DocumentStatus.WITHDRAWN,
        DocumentStatus.SIGNED
      ].includes(documentStatus)
    ) {
      return [downloadAction, deleteAction];
    }

    // "All" tab
    if (hasOneDocument) {
      return [
        downloadAction,
        ...(canWithdrawDocument ? [withdrawAction] : []),
        ...((canSign && isMissingSignature) || canApprove ? [signAction] : []),
        ...(canDeleteDocuments ? [deleteAction] : [])
      ];
    } else {
      return [downloadAction];
    }
  });

  return { actions };
}
