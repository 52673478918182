<template>
  <div class="flex flex-col">
    <div
      v-if="['idle', 'progress'].includes(removeSignStatus)"
      class="!p-0 text-black mb-4"
    >
      <I18nT keypath="dialogs.document_dialog.subtitles.remove_approver" />
    </div>
    <DeepSignAlert v-if="removeSignStatus === 'error'" class="mb-6" error>
      <I18nT keypath="errors.remove_approver_error" />
    </DeepSignAlert>

    <portal to="document-dialog-actions">
      <VBtnSecondary @click="emit('closeDialog')">
        <I18nT keypath="buttons.close" />
      </VBtnSecondary>
      <VBtnPrimary
        v-if="['idle', 'progress'].includes(removeSignStatus)"
        ref="removeSigneeButton"
        @click="removeApprover"
      >
        <I18nT keypath="buttons.remove_approver" />
      </VBtnPrimary>
    </portal>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useSignStore } from '@/stores/sign/sign.ts';
import { useGlobalStore } from '@/stores/global/global.ts';
import { useMessageStore } from '@/stores/message/message.ts';
import { useI18n } from 'vue-i18n';
import type { ComponentState } from '@/types/ui';
import { onMounted } from 'vue';
import { nextTick } from 'vue';
import { useDocumentContext } from '@/composables/useDocumentContext.ts';
import { useEmitter } from '@/composables/useEmitter.ts';

const emit = defineEmits(['closeDialog']);
const { currentDocument } = useDocumentContext();

const signStore = useSignStore();
const globalStore = useGlobalStore();
const messageStore = useMessageStore();
const i18n = useI18n();
const removeSignStatus = ref<ComponentState>('idle');
const removeSigneeButton = ref();
const emitter = useEmitter();

async function removeApprover() {
  try {
    removeSignStatus.value = 'progress';
    if (
      currentDocument.value &&
      currentDocument.value.documentId &&
      globalStore.selectedSignee?.signeeId
    ) {
      await signStore.removeSignee(
        currentDocument.value.documentId,
        globalStore.selectedSignee?.signeeId
      );
      removeSignStatus.value = 'success';
      messageStore.showMessage({
        text: i18n.t('snackbars.approver_removed'),
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      emitter.$emit('update-sign-status');
      emit('closeDialog');
    } else {
      removeSignStatus.value = 'error';
    }
  } catch (error) {
    removeSignStatus.value = 'error';
  }
}

onMounted(() => {
  nextTick(() => {
    removeSigneeButton.value.$el.focus();
  });
});
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.warning-icon {
  font-size: 18px;
  color: $warning;
}

.warning-card {
  @apply border rounded border-[#fb8c00] py-2.5 px-3.5 justify-items-center align-middle mb-4;
}

.warning-text {
  color: $warning;
  font-size: 14px;
  line-height: 20px;
}
</style>
