<template>
  <DeepDialog
    v-model="globalStore.documentDialog"
    width="600"
    :title="title"
    @click:outside.prevent="globalStore.documentDialogState = null"
    @keydown.esc="() => (globalStore.documentDialogState = null)"
  >
    <template #content>
      <ForwardForm
        v-if="globalStore.documentDialogState === 'forward'"
        @close-dialog="closeDialog"
      />
      <InviteForm
        v-if="globalStore.documentDialogState === 'invite'"
        @close-dialog="closeDialog"
      />
      <RemoveSigneeCard
        v-if="globalStore.documentDialogState === 'remove-signee'"
        @close-dialog="closeDialog"
      />
      <RemoveApproverCard
        v-if="globalStore.documentDialogState === 'remove-approver'"
        @close-dialog="closeDialog"
      />
      <ResendInvitationCard
        v-if="globalStore.documentDialogState === 'resend-invitation'"
        @close-dialog="closeDialog"
      />
      <UpdateEmailForm
        v-if="globalStore.documentDialogState === 'edit'"
        @close-dialog="closeDialog"
      />
      <ObserverForm
        v-if="globalStore.documentDialogState === 'add-observer'"
        @close-dialog="closeDialog"
      />
      <RemoveObserverCard
        v-if="globalStore.documentDialogState === 'remove-observer'"
        @close-dialog="closeDialog"
      />
    </template>

    <template #actions>
      <portal-target name="document-dialog-actions"></portal-target>
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/global/global';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import { PortalTarget } from 'portal-vue';

const i18n = useI18n();

const globalStore = useGlobalStore();

function closeDialog() {
  globalStore.documentDialog = false;
}

// Title
const title = computed(() => {
  switch (globalStore.documentDialogState) {
    case 'invite':
      return i18n.t('dialogs.document_dialog.titles.invite_signee');
    case 'forward':
      return i18n.t('dialogs.document_dialog.titles.forward_document');
    case 'remove-signee':
      return i18n.t('dialogs.document_dialog.titles.remove_signee');
    case 'remove-approver':
      return i18n.t('dialogs.document_dialog.titles.remove_approver');
    case 'resend-invitation':
      return i18n.t('dialogs.document_dialog.titles.resend_invitation');
    case 'edit':
      return i18n.t('dialogs.document_dialog.titles.edit_email');
    case 'add-observer':
      return i18n.t('dialogs.document_dialog.titles.add_observer');
    case 'remove-observer':
      return i18n.t('dialogs.document_dialog.titles.remove_observer');
    default:
      return '';
  }
});
</script>
