<template>
  <DeepDialog
    v-model="dialog"
    width="600"
    :title="i18n.t('titles.time_expired')"
    @keydown.esc="closeDialog"
  >
    <template #content>
      <div class="text-base mb-4">
        {{ i18n.t('texts.sign_again') }}
      </div>
    </template>
    <template #actions>
      <VBtnPrimary :text="i18n.t('buttons.cancel')" @click="closeDialog" />
    </template>
  </DeepDialog>
</template>

<script lang="ts" setup>
import { ref, onUnmounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const emitter = useEmitter();
const dialog = ref(false);

const i18n = useI18n();

emitter.$on('show-timeout-dialog', showDialog);

onUnmounted(() => {
  emitter.$off('show-timeout-dialog', showDialog);
});

function showDialog() {
  dialog.value = true;
}

function closeDialog() {
  dialog.value = false;
}
</script>

<style scoped lang="scss">
.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  white-space: normal;
}
</style>
