<template>
  <DeepDialog
    v-model="dialog"
    :fullscreen="$vuetify.display.mdAndDown"
    max-width="800"
    data-test-id="pdf-preview-dialog"
    :title="title"
    @keydown.esc="closeDialog"
  >
    <template #content>
      <div class="p-4 bg-[#757575] h-[900px]">
        <Loader v-if="!isPdfRendered" />
        <VuePdfEmbed
          v-show="documentUrl && isPdfRendered"
          id="pdf-preview"
          ref="pdfPreview"
          class="pdf-preview"
          :source="{
            verbosity: 0,
            url: documentUrl
          }"
          @rendered="handleRendered"
        />
      </div>
    </template>
    <template #actions>
      <VBtnSecondary
        color="primary"
        variant="outlined"
        data-test-id="preview-close"
        @click="closeDialog"
      >
        {{ i18n.t('buttons.close') }}
      </VBtnSecondary>
      <VBtnPrimary
        color="primary"
        data-test-id="preview-download"
        @click="emit('download-file', file)"
      >
        {{ i18n.t('buttons.download') }}
      </VBtnPrimary>
    </template>
  </DeepDialog>
</template>

<script lang="ts" setup>
import VuePdfEmbed from 'vue-pdf-embed';
import { ref, onUnmounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import type { AttachmentFile } from '@/types/Attachment';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const i18n = useI18n();
const emitter = useEmitter();
const dialog = ref(false);
const documentUrl = ref();
const file = ref<AttachmentFile>();
const title = ref();
const isPdfRendered = ref(false);

emitter.$on('show-pdf-preview-dialog', showDialog);

const emit = defineEmits(['download-file']);

onUnmounted(() => {
  emitter.$off('show-pdf-preview-dialog', showDialog);
});

function handleRendered() {
  isPdfRendered.value = true;
}

function showDialog(data: { file: AttachmentFile; documentUrl: string }) {
  dialog.value = true;
  if (data.documentUrl) documentUrl.value = data.documentUrl;
  if (data.file.name) title.value = data.file.name;
  if (data.file) file.value = data.file;
}

function closeDialog() {
  dialog.value = false;
}
</script>
