<template>
  <DeepDialog
    v-model="dialog"
    width="600"
    :style="{ zIndex: options.zIndex }"
    :title="title"
    @keydown.esc="cancel"
  >
    <template #content>
      <span v-show="!!message" class="text-black text-base">
        <div v-html="message"></div>
      </span>
    </template>
    <template #actions>
      <VBtnSecondary
        v-if="!options.noCancel"
        data-test-id="dialog-cancel-button"
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
        @click="cancel"
      />

      <VBtnPrimary
        data-test-id="dialog-continue-button"
        :text="
          options.confirmActionText
            ? options.confirmActionText
            : i18n.t('buttons.proceed')
        "
        @click="agree"
      />
    </template>
  </DeepDialog>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const i18n = useI18n();
const dialog = ref(false);
const resolve = ref();
const reject = ref();
const message = ref();
const title = ref();
const options = ref({
  width: 500,
  confirmActionText: null,
  noCancel: false,
  zIndex: 2401
});

function open(titleParam, messageParam, actionParam, optionsParam) {
  dialog.value = true;
  title.value = titleParam;
  message.value = messageParam;
  options.value = Object.assign(options.value, optionsParam);
  return new Promise((resolveParam, rejectParam) => {
    resolve.value = resolveParam;
    reject.value = rejectParam;
  });
}

function agree() {
  resolve.value(true);
  dialog.value = false;
}

function cancel() {
  resolve.value(false);
  dialog.value = false;
}

watch(
  () => dialog.value,
  (newValue, oldValue) => {
    if (!newValue && oldValue) {
      resolve.value(false);
    }
  }
);

defineExpose({
  agree,
  cancel,
  open
});
</script>
