import type { Route } from 'vue-router';
import AuthService from '@/services/auth-service';
import { setDeepBoxBackRoute } from '@/composables/useUtils';
import { useDocumentStore } from '@/stores/document/document';
import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { getCurrentStatusPage } from '@/services/documentService';
import { globalRouterReference } from '@/router/globalRouterReference';
import { useErrorStore } from '@/stores/error/error';
import { AxiosError } from 'axios';

interface Filters {
  limit: number;
  filterDeepboxDocumentId?: string;
  statuses?: string;
  order: string;
}

function getRouteKey(routePath: string) {
  return routePath.substring(routePath.lastIndexOf('/') + 1);
}

function setBackRoute(route: Route) {
  const backRoute = route.query?.backRoute?.toString().substring(1) || '';
  setDeepBoxBackRoute(AuthService.user?.sub, backRoute);
}

async function handleDocumentCreation(route: Route) {
  const documentStore = useDocumentStore();
  try {
    await documentStore.createDocumentFromDeepBox({
      deepboxDocumentId: route.params.id as string,
      scanPredefinedSignees: true,
      addInitiatorAsSignee: true,
      signeeAttachmentsAllowed: false
    });
    if (documentStore.document) {
      await globalRouterReference.router!.replace({
        name: 'document-create',
        params: { document: documentStore.document?.documentId },
        query: { ...route.query }
      });
    }
    documentStore.document = undefined;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 409) {
      console.info('Document already exists, redirecting to the correct page');
      await fetchDocuments(route, documentStore, {
        limit: 1,
        filterDeepboxDocumentId: route.params.id as string,
        order: 'creationTime desc'
      });
    } else {
      const errorStore = useErrorStore();
      errorStore.error = error as AxiosError;
      globalRouterReference.router!.push({ name: 'error-page' });
      console.error('Error creating document', error);
    }
  }
}

async function fetchDocuments(
  route: Route,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  documentStore: any,
  filters: Filters
) {
  await documentStore.fetchDocuments({ params: filters });
  if (documentStore.documents) {
    const lastDocument = documentStore.documents[0];
    if (lastDocument) {
      const destination = getCurrentStatusPage(
        AuthService.user?.email,
        lastDocument
      );
      if (getRouteKey(destination) !== getRouteKey(route.path)) {
        await globalRouterReference.router!.push({
          path: destination,
          query: { ...route.query }
        });
      }
    }
  }
}

const deepBoxDocumentGuardian = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (to: Route, _from: Route) => {
    setBackRoute(to);
    const documentStore = useDocumentStore();

    switch (to.name) {
      // If you come from /deepbox/:id
      case 'deepbox-id':
        await handleDocumentCreation(to);
        break;
      // If you come from /deepbox/:id/pending
      case 'deepbox-id-pending':
        await fetchDocuments(to, documentStore, {
          limit: 1,
          filterDeepboxDocumentId: to.params.id as string,
          statuses: [DocumentStatus.IN_PROGRESS, DocumentStatus.DRAFT].join(),
          order: 'creationTime desc'
        });
        break;
      // If you come from /deepbox/:id/signed
      default:
        await fetchDocuments(to, documentStore, {
          limit: 1,
          filterDeepboxDocumentId: to.params.id as string,
          statuses: [DocumentStatus.SIGNED].join(),
          order: 'creationTime desc'
        });
    }
  };
};

export default deepBoxDocumentGuardian;
