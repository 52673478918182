<template>
  <portal :to="getPortalTitle">
    {{ i18n.t('titles.sms_code_verification') }}
  </portal>
  <portal :to="getPortalSubtitle">
    <div class="mb-6 text-md">
      {{ i18n.t('texts.check_identity_sms_code_hint') }}
    </div>
  </portal>
  <div class="d-flex flex-column" style="width: 100%">
    <!-- <div class="sms-code-title">
      {{ i18n.t('titles.sms_code_verification') }}
    </div> -->
    <!-- <div class="sms-code-subtitle">
      {{ i18n.t('texts.check_identity_sms_code_hint') }}
    </div> -->
    <v-row no-gutters>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="form.firstname"
          disabled
          :class="{
            'mr-2': $vuetify.display.mdAndUp,
            'mb-5': !$vuetify.display.mdAndUp
          }"
          hide-details="auto"
          :label="i18n.t('labels.firstname')"
          variant="outlined"
        />
      </v-col>
      <v-col :cols="12" :md="6">
        <v-text-field
          v-model="form.lastname"
          disabled
          :class="{ 'ml-2': $vuetify.display.mdAndUp }"
          hide-details="auto"
          :label="i18n.t('labels.lastname')"
          variant="outlined"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5" no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.displayName"
          hide-details="auto"
          :error-messages="displayNameErrors"
          :label="i18n.t('labels.display_name')"
          variant="outlined"
          class="display_name"
          @update:model-value="clearErrors"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5 mb-2" no-gutters>
      <v-col cols="12">
        <FieldPhone
          v-bind="bindProps"
          id="phone-input"
          :rules="phoneNumberErrors"
          :invalid-message="i18n.t('errors.invalid_phone_number')"
          :label="i18n.t('labels.phone_number')"
          :country-label="i18n.t('labels.country')"
          hide-details="auto"
          :placeholder="i18n.t('placeholders.phone_number')"
          :valid-characters-only="true"
          @update:model-value="onInput"
        />
      </v-col>
    </v-row>

    <portal :to="getPortalActions">
      <!-- ACTIONS -->
      <VBtnSecondary
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
        @click="handleIdentificationCancel"
      />
      <VBtnPrimary
        :disabled="!isFormValid"
        :loading="deepAdminStore.updateUserPending"
        color="primary"
        :text="i18n.t('buttons.save')"
        @click="updateDeepAdminUser"
      />
      <!-- /ACTIONS -->
    </portal>
  </div>
</template>

<script setup lang="ts">
import AuthService from '@/services/auth-service';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin';
import { useSignStore } from '@/stores/sign/sign';
import { AuthorityService } from '@/types/enums/AuthorityService';
import { ref, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useEmitter } from '@/composables/useEmitter';
import type { errorType } from '@/types/PhoneForm';
import type { ParsedPhoneNumber } from 'awesome-phonenumber';
import { useRoute } from 'vue-router';

const emit = defineEmits(['show-warning', 'done', 'cancel']);
const deepAdminStore = useDeepAdminStore();
const signStore = useSignStore();
const i18n = useI18n();
const emitter = useEmitter();
const route = useRoute();

const form = reactive({
  firstname: AuthService.user.given_name,
  lastname: AuthService.user.family_name,
  displayName: AuthService.user.display_name,
  phoneNumber: '',
  id: AuthService.user.sub
});

const phone = ref<ParsedPhoneNumber>();
const errors = ref<errorType>();

const bindProps = reactive({
  defaultCountry: 'CH',
  preferredCountries: ['CH', 'IT', 'FR', 'DE', 'AT', 'LI'],
  mode: 'international',
  disabledFetchingCountry: false,
  disabled: false,
  disabledFormatting: false,
  required: true,
  enabledFlags: true,
  ignoredCountries: [],
  autocomplete: 'on',
  name: 'number',
  maxLen: 25,
  dropdownOptions: { disabledDialCode: true, tabindex: 0 },
  inputOptions: { showDialCode: false, tabindex: 0 },
  enabledCountryCode: false,
  validCharactersOnly: true
});

const displayNameErrors = computed(() => {
  return errors.value || [];
});

const phoneNumberErrors = computed(() => {
  if (!phone.value) return [];
  const numberPattern = /^\+?\d+(\s\d+)*$/;

  if (
    phone.value.number &&
    phone.value.number.international &&
    numberPattern.test(phone.value.number.international)
  ) {
    return [];
  } else {
    return [i18n.t('errors.invalid_phone_number')];
  }
});

const isFormValid = computed(() => {
  return phone.value && phone.value.valid && Boolean(phoneNumberErrors.value);
});

const getPortalTitle = computed(() => {
  if (route.name === 'document-batch-sign') {
    return 'batch-identify-dialog-title';
  } else {
    return 'identify-dialog-title';
  }
});

const getPortalSubtitle = computed(() => {
  if (route.name === 'document-batch-sign') {
    return 'batch-identify-dialog-subtitle';
  } else {
    return 'identify-dialog-subtitle';
  }
});

const getPortalActions = computed(() => {
  if (route.name === 'document-batch-sign') {
    return 'batch-identify-dialog-actions';
  } else {
    return 'identify-dialog-actions';
  }
});

function onInput(phoneObject: ParsedPhoneNumber) {
  if (phoneObject.number) {
    form.phoneNumber = phoneObject.number.international;
  }
  phone.value = phoneObject;
}

function clearErrors() {
  errors.value = undefined;
}

function updateDeepAdminUser() {
  deepAdminStore
    .updateUser({
      id: form.id,
      firstName: form.firstname,
      lastName: form.lastname,
      display_name: form.displayName,
      country: phone.value?.regionCode ? phone.value?.regionCode : '',
      phonenumber: form.phoneNumber
    })
    .then(() => {
      if (phone.value && phone.value.regionCode === 'CH') {
        signStore.enableAuthorityService(AuthorityService.MOBILE_ID);
        emit('done');
        emitter.$emit('enable-sms-code');
      } else {
        emit('show-warning');
      }
    })
    .catch((e) => {
      const non_field_errors = e.response.data.non_field_errors;
      errors.value = non_field_errors;
    });
}

function handleIdentificationCancel() {
  emit('cancel');
}
</script>

<style scoped lang="scss">
.sms-code-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 12px;
}

.sms-code-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}

.display_name :deep(.v-input__details) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0%;
}
</style>
