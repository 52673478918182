<template>
  <div>
    <UserSigningSwitch />
    <slot></slot>

    <div class="flex flex-column">
      <OrderInviteesSwitch />

      <v-expand-transition
        v-if="!createStore.hasApprovers && createStore.ordered && userIsSigning"
      >
        <div class="flex flex-row flex-grow-1 panel-content mb-2">
          <div class="drawer-footnote mr-4">
            {{ getSignOrderContextDisplayValue }}
          </div>
          <div class="flex flex-row">
            <button
              class="deep-text-button"
              @click="updateContextSignOrder(SignOrder.FIRST)"
            >
              {{ i18n.t('actions.first') }}
            </button>
            <v-icon class="px-3" color="#D9D9D9" size="16">
              fal fa-pipe
            </v-icon>
            <button
              class="deep-text-button"
              @click="updateContextSignOrder(SignOrder.LAST)"
            >
              {{ i18n.t('actions.last') }}
            </button>
          </div>
        </div>
      </v-expand-transition>
    </div>

    <PlaceSignaturesSwitch />

    <!-- MOVE SIGNATURES -->
    <v-expand-transition class="mb-3">
      <div
        v-if="globalStore?.pageCount > 1 && globalStore.pdfPreviewRendered"
        v-show="createStore.placeSignaturesModel"
        class="move-to-page mt-[-12px]"
      >
        <MoveSignaturesMenu />
      </div>
    </v-expand-transition>
    <!-- /MOVE SIGNATURES -->
  </div>
</template>
<script setup lang="ts">
import AuthService from '@/services/auth-service.ts';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount } from 'vue';
import type { Signee } from '@/types/Signee.ts';
import SignatureService from '@/services/signatureService.ts';
import { useCreateStore } from '@/stores/create.ts';
import { useGlobalStore } from '@/stores/global/global.ts';
import { SignOrder } from '@/types/enums/SignOrder.ts';
import { updateSignOrderType } from '@/api/deepsign/sign-order-type.ts';

const i18n = useI18n();
const createStore = useCreateStore();
const globalStore = useGlobalStore();

const userIsSigning = computed(
  () =>
    !!createStore.document?.signeesOrdered.find((group) =>
      group.find((invitee: Signee) =>
        SignatureService.compareString(invitee?.email, AuthService.user?.email)
      )
    )
);

const getSignOrderContextDisplayValue = computed(() => {
  if (!createStore.signOrderContextLabel) {
    return '';
  } else if (
    createStore.signOrderContextLabel.toLowerCase() ===
    AuthService.user?.email.toLowerCase()
  ) {
    return i18n.t('texts.i_am_signing');
  } else if (createStore.validateEmail(createStore.signOrderContextLabel)) {
    return i18n.t('texts.email_signs', {
      email: createStore.signOrderContextLabel
    });
  } else {
    return i18n.t('texts.domain_signs', {
      domain: createStore.signOrderContextLabel
    });
  }
});

function updateOrderedGroupsFromPayload(
  groups: Array<Array<string>>
): Array<Array<Signee | undefined>> {
  return groups.map((group) =>
    group.map((signeeId: string) =>
      createStore.signeesOrderedFlat.find(
        (signee) => signee.signeeId === signeeId
      )
    )
  );
}

async function updateContextSignOrder(type: SignOrder) {
  const response = await updateSignOrderType(
    createStore.document!.documentId,
    type
  );

  createStore.document!.signeesOrdered = updateOrderedGroupsFromPayload(
    response.data
  ) as Array<Array<Signee>>;
}

onBeforeMount(async () => {
  createStore.ordered = createStore
    .document!.signeesOrdered.flat()
    .some((invitee) => invitee.signOrder === 1);
});
</script>
