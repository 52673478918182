<template>
  <portal :to="getPortalTitle">
    <img
      :src="`/svg/authority-services/${authorityService}-logo.svg`"
      class="h-5"
    />
  </portal>
  <div class="d-flex flex-column">
    <span class="font-semibold text-base mb-4">
      {{ i18n.t('titles.successfully_verified') }}
    </span>
    <v-card class="successful-verified-card" flat rounded="lg">
      <v-col class="d-flex flex-column pa-0 align-center">
        <v-icon color="success" size="44"> fas fa-circle-check</v-icon>
        <div class="mt-3 deep-caption text-center">
          <div class="text-hint-message">
            {{ i18n.t(`authorities.${authorityService}_can_sign`) }}
          </div>
        </div>
      </v-col>
    </v-card>
  </div>
  <portal :to="getPortalActions">
    <VBtnPrimary :text="i18n.t('buttons.close')" @click="closeDialog" />
  </portal>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

defineProps({
  authorityService: {
    type: String,
    default: ''
  }
});

const i18n = useI18n();
const route = useRoute();

const getPortalTitle = computed(() => {
  if (route.name === 'document-batch-sign') {
    return 'batch-identify-dialog-title';
  } else {
    return 'identify-dialog-title';
  }
});

const getPortalActions = computed(() => {
  if (route.name === 'document-batch-sign') {
    return 'batch-identify-dialog-actions';
  } else {
    return 'identify-dialog-actions';
  }
});

const emit = defineEmits(['close-dialog']);

function closeDialog() {
  emit('close-dialog');
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.successful-verified-card {
  background-color: $grey-lighten-4;
  padding: 24px !important;
}

.text-hint-message {
  max-width: 200px !important;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.successful-verified-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 28px;
}
</style>
