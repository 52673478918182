import AuthService from '@/services/auth-service';
import { computed } from 'vue';

export function useOnboarding() {
  const baseUrl = computed(() => {
    return `${window.location.origin}${window.location.pathname}`;
  });

  async function openRegisterPage(
    redirectUrl: string,
    email: string | undefined
  ) {
    const encodedRedirectUrl = redirectUrl.startsWith(baseUrl.value)
      ? redirectUrl
      : window.location.origin + redirectUrl;

    await AuthService.register({
      redirectUri: encodedRedirectUrl,
      loginHint: email,
      prompt: 'force_login_hint'
    });
  }

  async function logoutAndSetRedirectUrlParam(email: string | undefined) {
    const url = new URL(window.location.href);
    url.searchParams.set('autoRedirect', 'deep-sign');

    if (email && !url.searchParams.has('email')) {
      url.searchParams.set('email', email);
    }

    await AuthService.logout({
      redirectUri: url.href
    });
  }

  return {
    openRegisterPage,
    logoutAndSetRedirectUrlParam
  };
}
